import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import CustomTextInput from "../../components/CustomTextInput";
import Button from "../../components/Button";
import {
  arcticMistColor,
  primaryColor,
  silverCloudColor,
  silverSageColor,
  slateGrayColor,
  whiteColor,
} from "../../assets/colors";
import { AttachOutlineIcon, CloseIcon } from "../../assets/icons";
import CustomDropDown from "../../components/CustomDropDown";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createTicketRequestAction,
  getTicketCategoriesRequestAction,
} from "../../redux/actions/ticketActions";
import { CREATE_TICKET_STARTED } from "../../redux/actionTypes";
import { TICKETS } from "../routes";

const CreateTicket = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const { ticketCategories, rType } = useSelector((state) => state.ticket);

  const [
    { subject, category, subCategory, message, attachment, errors },
    setInitState,
  ] = useState({
    subject: "",
    category: "",
    subCategory: "",
    message: "",
    attachment: null,
    errors: {},
  });

  useEffect(() => {
    dispatch(getTicketCategoriesRequestAction());
  }, []);

  const setState = (state) => {
    setInitState((prevState) => ({ ...prevState, ...state }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const errorObj = {};

    if (subject === "") errorObj.subject = "لطفا عنوان تیکت را وارد کنید.";
    if (category === "") errorObj.category = "لطفا دپارتمان را انتخاب کنید.";
    // if (subCategory === "")
    //   errorObj.subCategory = "لطفا زیر گروه را انخاب کنید.";
    if (message === "") errorObj.message = "لطفا متن تیکت را وارد کنید.";

    setState({ errors: errorObj });

    if (Object.keys(errorObj).length > 0) return;

    const letters = /^[A-Za-z-0-9_.-@-#-%-!-*-=-^-" "|]*$/gi;
    let firstletter = message.slice(0, 1);
    let inputLang;
    let findLang = letters.exec(firstletter);
    if (findLang) {
      inputLang = "en";
    } else {
      inputLang = "fa";
    }

    let formData = new FormData();
    formData.append("lang", inputLang);
    formData.append("source", "Website");
    subject && formData.append("subject", subject);
    formData.append("category_id", subCategory ? subCategory : category);
    formData.append("text", message);
    attachment && formData.append("attachment", attachment);

    dispatch(createTicketRequestAction({ data: formData, navigate }));
  };

  const handleChipClick = () => {
    fileInputRef.current.click();
  };

  const renderCreateTicketDrawer = (
    <SwipeableDrawer
      anchor="bottom"
      open
      onClose={() => navigate(-1)}
      // onOpen={() => setOpen(true)}
      // componentsProps={{
      //   backdrop: {
      //     sx: { left: { lg: drawerWidth }, backgroundColor: "transparent" },
      //   },
      // }}
      PaperProps={{
        sx: {
          borderTopRightRadius: { xs: 20, lg: 25 },
          borderTopLeftRadius: { xs: 20, lg: 25 },
          // p: 2,
          boxShadow: "0px -17px 44.3px 0px #7F91A133",
        },
      }}
      sx={{ display: { lg: "none" } }}
      disableSwipeToOpen
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: "13px",
          py: 2.25,
        }}
      >
        <Typography sx={{ fontWeight: 700 }}>ساخت تیکت جدید</Typography>
      </Box>
      <Divider variant="middle" sx={{ borderColor: silverCloudColor }} />
      <Box sx={{ px: "27px", py: "18px" }}>
        <CustomTextInput
          label="عنوان تیکت"
          value={subject}
          placeholder="... عنوان تیکت را وارد کنید"
          onChange={(e) =>
            setState({
              subject: e.target.value,
              errors: { ...errors, subject: "" },
            })
          }
          helperText={errors.subject}
          error={errors.subject}
        />
        <Box sx={{ display: "flex", mb: 1, gap: 2 }}>
          <CustomDropDown
            label="دپارتمان"
            placeholder="عنوان تیکت را وارد کنید ..."
            items={ticketCategories.filter((item) => item.parent_id === null)}
            schema={{ label: "category_fa", value: "id" }}
            onChange={(e) =>
              setState({
                category: e.target.value,
                errors: { ...errors, category: "" },
              })
            }
            helperText={errors.category}
            error={errors.category}
          />
          <CustomDropDown
            label="زیر گروه"
            placeholder="عنوان تیکت را وارد کنید ..."
            items={ticketCategories.filter(
              (item) => item.parent_id === Number(category)
            )}
            schema={{ label: "category_fa", value: "id" }}
            disabled={category === ""}
            helperText={errors.subCategory}
            error={errors.subCategory}
            onChange={(e) => setState({ subCategory: e.target.value })}
          />
        </Box>
        <CustomTextInput
          label="پیام شما"
          placeholder="... متن تیکت را وارد کنید"
          multiline
          value={message}
          rows={4}
          helperText={errors.message}
          error={errors.message}
          onChange={(e) =>
            setState({
              message: e.target.value,
              errors: { ...errors, message: "" },
            })
          }
        />
        <Box sx={{ display: "flex", flexDirection: "row-reverse", mb: "17px" }}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={(e) => setState({ attachment: e.target.files[0] })}
            accept=".png, .jpg, .jpeg"
          />
          <Chip
            label="افزودن پیوست"
            onClick={handleChipClick}
            icon={<AttachOutlineIcon color={primaryColor} width={18} />}
            color="silverSage"
            disabled={attachment}
          />
        </Box>

        {attachment && (
          <Box
            sx={{ display: "flex", flexDirection: "row-reverse", mb: "17px" }}
          >
            <Chip
              label={attachment.name}
              icon={
                <Avatar
                  src={URL.createObjectURL(attachment)}
                  sx={{ width: 35, height: 23, borderRadius: "3px" }}
                />
              }
              sx={{
                width: 138,
                fontSize: 12,
                borderRadius: 2,
                color: slateGrayColor,
              }}
              onDelete={() => setState({ attachment: null })}
            />
          </Box>
        )}
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            text="انصراف"
            color="arcticMist"
            onClick={() => navigate(TICKETS)}
          />
          <Button
            text="ثبت تیکت"
            onClick={onSubmit}
            loading={rType == CREATE_TICKET_STARTED}
          />
        </Box>
      </Box>
    </SwipeableDrawer>
  );

  return (
    <Box sx={{ flex: 1, backgroundColor: whiteColor }}>
      <Box
        sx={{
          borderBottom: `1px solid ${silverSageColor}`,
          p: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontWeight: 800 }}>ساخت تیکت جدید</Typography>
        <IconButton
          sx={{ backgroundColor: arcticMistColor }}
          onClick={() => navigate(TICKETS)}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item lg={4}>
          <CustomTextInput
            label="عنوان تیکت"
            value={subject}
            placeholder="... عنوان تیکت را وارد کنید"
            onChange={(e) =>
              setState({
                subject: e.target.value,
                errors: { ...errors, subject: "" },
              })
            }
            helperText={errors.subject}
            error={errors.subject}
          />
        </Grid>
        <Grid item lg={4}>
          <CustomDropDown
            label="دپارتمان"
            placeholder="عنوان تیکت را وارد کنید ..."
            items={ticketCategories.filter((item) => item.parent_id === null)}
            schema={{ label: "category_fa", value: "id" }}
            onChange={(e) =>
              setState({
                category: e.target.value,
                errors: { ...errors, category: "" },
              })
            }
            helperText={errors.category}
            error={errors.category}
          />
        </Grid>
        <Grid item lg={4}>
          <CustomDropDown
            label="زیر گروه"
            placeholder="عنوان تیکت را وارد کنید ..."
            items={ticketCategories.filter(
              (item) => item.parent_id === Number(category)
            )}
            schema={{ label: "category_fa", value: "id" }}
            disabled={category === ""}
            helperText={errors.subCategory}
            error={errors.subCategory}
            onChange={(e) => setState({ subCategory: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextInput
            label="پیام شما"
            placeholder="... متن تیکت را وارد کنید"
            multiline
            value={message}
            rows={4}
            helperText={errors.message}
            error={errors.message}
            onChange={(e) =>
              setState({
                message: e.target.value,
                errors: { ...errors, message: "" },
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => setState({ attachment: e.target.files[0] })}
              accept=".png, .jpg, .jpeg"
            />
            <Chip
              label="افزودن پیوست"
              onClick={handleChipClick}
              icon={<AttachOutlineIcon color={primaryColor} width={18} />}
              color="silverSage"
              disabled={attachment}
            />
            {attachment && (
              <Chip
                label={attachment.name}
                icon={
                  <Avatar
                    src={URL.createObjectURL(attachment)}
                    sx={{ width: 35, height: 23, borderRadius: "3px" }}
                  />
                }
                sx={{
                  mr: 1,
                  width: 138,
                  fontSize: 12,
                  borderRadius: 2,
                  color: slateGrayColor,
                }}
                onDelete={() => setState({ attachment: null })}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} sx={{ ml: "auto" }}>
          <Button
            text="انصراف"
            color="arcticMist"
            onClick={() => navigate(TICKETS)}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Button
            text="ثبت تیکت"
            onClick={onSubmit}
            loading={rType == CREATE_TICKET_STARTED}
          />
        </Grid>
      </Grid>
      {renderCreateTicketDrawer}
    </Box>
  );
};

export default CreateTicket;
