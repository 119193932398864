import { Tab, Tabs, styled } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { TrashIcon } from "../../assets/icons";
import {
  aliceBlueColor,
  fireEngineRedColor,
  mintMistColor,
  primaryColor,
  slateGrayColor,
} from "../../assets/colors";
import { ACCOUNT } from "../routes";

const AccountsMenu = () => {
  const navigate = useNavigate();
  const { tab } = useParams();

  return (
    <>
      <Tabs
        value={tab}
        onChange={(event, newValue) => navigate(`${ACCOUNT}/${newValue}`)}
        aria-label="wrapped label tabs example"
        variant="scrollable"
        scrollButtons={false}
        allowScrollButtonsMobile
        // sx={{ borderBottom: `1px solid ${silverCloudColor}` }}
        indicatorColor="transparent"
        sx={{
          display: { xs: "flex", lg: "block" },
          minHeight: "auto",
          "& .MuiTabs-flexContainer": {
            flexDirection: { lg: "column" },
          },
        }}
      >
        <StyledTab value="profile" label="پروفایل کاربری من" />
        <StyledTab value="setting" label="نتظیمات عمومی" />
        <StyledTab value="plan" label="ارتقا اشتراک" />
        <StyledTab value="account-events" label="رویداد های حساب کاربری" />
        <StyledTab value="change-password" label="تغییر رمز عبور حساب" />
        <StyledTab
          value="delete-account"
          label="حذف حساب کاربری"
          sx={{
            display: { lg: "none" },
            "&.Mui-selected": {
              color: `${fireEngineRedColor}!important`,
              background: `${aliceBlueColor}!important`,
            },
          }}
        />
      </Tabs>
      <Tabs
        value={tab}
        onChange={(event, newValue) => navigate(`${ACCOUNT}/${newValue}`)}
        aria-label="wrapped label tabs example"
        variant="scrollable"
        scrollButtons={false}
        allowScrollButtonsMobile
        // sx={{ borderBottom: `1px solid ${silverCloudColor}` }}
        indicatorColor="transparent"
        sx={{
          display: { xs: "none", lg: "block" },
          minHeight: "auto",
          "& .MuiTabs-flexContainer": {
            flexDirection: { lg: "column" },
          },
          mt: "auto",
        }}
      >
        <StyledTab
          value="delete-account"
          label="حذف حساب کاربری"
          icon={<TrashIcon />}
          sx={{
            alignItems: "center",
            color: `${fireEngineRedColor}!important`,
            "&.Mui-selected": {
              background: `${aliceBlueColor}!important`,
            },
          }}
          iconPosition="start"
        />
      </Tabs>
    </>
  );
};

export default AccountsMenu;

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  minHeight: 54,
  color: slateGrayColor,
  fontWeight: 500,
  alignItems: "flex-start",
  [theme.breakpoints.down("lg")]: {
    alignItems: "center",
    minHeight: 34,
    fontSize: 12,
  },
  // background: sereneSkyBlueColor,
  padding: "6.25px 10px",
  // fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(0.8),
  marginLeft: theme.spacing(0.8),
  borderRadius: 38,
  // [theme.breakpoints.up("lg")]: {
  // },
  // color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-selected": {
    color: primaryColor,
    background: mintMistColor,
  },
  // "&.Mui-focusVisible": {
  //   backgroundColor: "rgba(100, 95, 228, 0.32)",
  // },
}));
