import {
  Avatar,
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  errorColor,
  silverSageColor,
  slateGrayColor,
} from "../../../assets/colors";
import CustomTextInput from "../../../components/CustomTextInput";
import { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import Title from "../../../components/Title";
import MaterialUISwitch from "../../../components/Switch";
import {
  ChevronDownIcon,
  CloseIcon,
  EuroIcon2,
  GridIcon,
  ImageIcon,
  InfoIcon,
  LinkIcon1,
} from "../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CurrencyInput from "../../../components/CurrencyInput";
import CustomAutocomplete from "../../../components/CustomAutocomplete";
import {
  createProductRequestAction,
  getProductsListRequestAction,
} from "../../../redux/actions/productActions";
import AddProduct from "../ProductsList/AddProduct";
import { PaylinkContext } from "../../../context/PyalinkContext";

const ProductConfig = ({ selectedTab }) => {
  const dispatch = useDispatch();
  const { productList: pp, rType } = useSelector((state) => state.product);
  const [open, setOpen] = useState(false);
  const [removeSelectedProducts, setRemoveSelectedProducts] = useState([]);
  const [productList, setProductList] = useState(pp);

  console.log(pp)
  const {
    state: {
      createDiscount,
      successfulMessage,
      unsuccessfulMessage,
      confirmationPage,
      customConfirmationPage,
      determination,
      day,
      count,
      showContactInformation,
      products,
      discountCode,
      percent,
      constant,
      errors,
    },
    setState,
  } = useContext(PaylinkContext);

  useEffect(() => {
    dispatch(
      getProductsListRequestAction({
        limit: 1000,
        offset: 1,
        onSuccess: (data) => {
          setRemoveSelectedProducts(data);
          setProductList(data);
          setState({ products: [] });
        },
      })
    );
  }, []);

  const handleProductChange = (event, value) => {
    if (value) {
      // Add selected product to the products array
      let selectedProducts = [...products, value];
      setState({ products: selectedProducts, errors: {} });

      // Remove the selected product from the productList
      let updatedProductList = productList.filter(
        (product) => product.title !== value.title
      );
      setProductList(updatedProductList);

      // Update state and clear any errors
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   products: "",
      // }));
    }
  };

  const handleProductRemove = (productToRemove) => {
    // Remove the product from the products array
    let filteredProducts = products.filter(
      (product) => product.id !== productToRemove.id
    );
    setState({ products: filteredProducts });

    // Add the removed product back to the productList
    let updatedProductList = [...productList, productToRemove];
    setProductList(updatedProductList);
  };

  const renderTab = () => {
    switch (selectedTab) {
      case "PaymentPage":
        return (
          <>
            <Box sx={{ mb: 1 }}>
              <CustomAutocomplete
                label="انتخاب محصول"
                endAdornment={
                  <InputAdornment position="start">
                    <GridIcon />
                  </InputAdornment>
                }
                onChange={handleProductChange}
                error={errors.products}
                options={productList}
                getOptionLabel={(option) => option.title}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ direction: "rtl" }} {...props}>
                    <Avatar src={option.image} variant="rounded" />
                    <Box sx={{ ml: "auto", mr: 1, width: 170 }}>
                      <Typography
                        sx={{
                          fontSize: 14,
                          mb: 0.5,
                          fontWeight: 600,
                          textAlign: "right",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {option.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: slateGrayColor,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",

                          textAlign: "right",
                        }}
                      >
                        {option.detail}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <EuroIcon2 style={{ width: 15 }} />
                      <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                        {option.amount}
                      </Typography>
                    </Box>
                  </Box>
                )}
                hideHelperText
              />
              <Typography sx={{ color: errorColor, fontSize: 12, mt: 0.5 }}>
                {errors.products}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
              <Chip
                label="+ افزودن"
                onClick={() => setOpen(true)}
                // icon={<AttachOutlineIcon color={primaryColor} />}
                color="brightTurquoise"
              />
            </Box>
            <Box
              sx={{
                pb: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              {products.map((product) => (
                <Chip
                  key={product.id}
                  label={`${product.title}: ${product.symbol}${product.amount}`}
                  icon={<CloseIcon width={17} height={17} />}
                  color="arcticMist2"
                  onClick={() => handleProductRemove(product)}
                  sx={{ mb: 1, fontWeight: 500 }}
                />
              ))}
            </Box>
            <Title text="تنظیمات پیشرفته" />
            <Box
              sx={{
                pt: 3,
                pb: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                امکان ایجاد کد تخفیف
              </Typography>
              <MaterialUISwitch
                checked={createDiscount}
                onChange={() =>
                  setState({
                    createDiscount: !createDiscount,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                top: createDiscount ? 0 : -70,
                visibility: createDiscount ? "visible" : "hidden",
                opacity: createDiscount ? 1 : 0,
                height: createDiscount ? 68.13 : 0,
                transition: "0.6s all",
              }}
            >
              <CustomTextInput
                startAdornment={
                  <Box
                    sx={{
                      height: 40.13,
                      borderRight: `1px solid ${silverSageColor}`,
                      pr: 0.5,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: 12, whiteSpace: "unset" }}>
                      شناسه کد
                    </Typography>
                  </Box>
                }
                sx={{ mt: "0!important" }}
                value={discountCode}
                onChange={(e) =>
                  setState({
                    discountCode: e.target.value,
                    constant: "",
                    errors: { ...errors, discountCode: "" },
                  })
                }
                helperText={errors.discountCode}
                error={errors.discountCode}
                placeholder="Iran12 :مثلا"
                maxLength={10}
                englishAlphanumeric
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                top: createDiscount ? 0 : -70,
                visibility: createDiscount ? "visible" : "hidden",
                opacity: createDiscount ? 1 : 0,
                height: createDiscount ? 68.13 : 0,
                transition: "0.6s all",
              }}
            >
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <CustomTextInput
                  startAdornment={
                    <Box
                      sx={{
                        height: 40.13,
                        borderRight: `1px solid ${silverSageColor}`,
                        pr: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 12, whiteSpace: "unset", mx: 1 }}
                      >
                        درصد
                      </Typography>
                    </Box>
                  }
                  sx={{ mt: "0!important" }}
                  value={percent}
                  placeholder="%20 مثلا"
                  onChange={(e) =>
                    setState({
                      percent: e.target.value,
                      constant: "",
                      errors: { ...errors, percent: "" },
                    })
                  }
                  error={errors.percent}
                  hideHelperText
                  number
                />
                <Typography sx={{ fontSize: 12 }}>یا</Typography>
                <CustomTextInput
                  startAdornment={
                    <Box
                      sx={{
                        height: 40.13,
                        borderRight: `1px solid ${silverSageColor}`,
                        pr: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: 12, whiteSpace: "unset" }}>
                        عدد ثابت
                      </Typography>
                    </Box>
                  }
                  sx={{ mt: "0!important" }}
                  placeholder="مثلا: 5 یورو"
                  onChange={(e) =>
                    setState({
                      constant: e.target.value,
                      percent: "",
                      errors: { ...errors, percent: "" },
                    })
                  }
                  value={constant}
                  error={errors.percent}
                  hideHelperText
                  number
                />
              </Box>
              <Typography sx={{ color: errorColor, fontSize: 12, mt: 1 }}>
                {errors.percent}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                my: 1,
              }}
            >
              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                تعیین میزان پرداخت
              </Typography>
              <MaterialUISwitch
                checked={determination}
                onChange={(e) => setState({ determination: e.target.checked })}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                top: determination ? 0 : -70,
                visibility: determination ? "visible" : "hidden",
                opacity: determination ? 1 : 0,
                height: determination ? 68.13 : 0,
                transition: "0.6s all",
              }}
            >
              <Box sx={{ display: "flex", gap: 2 }}>
                <CustomTextInput
                  startAdornment={
                    <Box
                      sx={{
                        height: 40.13,
                        borderRight: `1px solid ${silverSageColor}`,
                        pr: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: 12, whiteSpace: "unset" }}>
                        بر اساس روز
                      </Typography>
                    </Box>
                  }
                  sx={{ mt: "0!important" }}
                  onChange={(e) => {
                    let value = e.target.value;
                    setState({
                      day: value.startsWith("0")
                        ? value.replace(/^0+/, "")
                        : value,
                    });
                  }}
                  value={day}
                  endAdornment={
                    <InputAdornment
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "auto",
                      }}
                      position="end"
                    >
                      <IconButton
                        size="small"
                        sx={{ p: 0.25, transform: "rotate(180deg)" }}
                        onClick={(e) => setState({ day: Number(day) + 1 })}
                      >
                        <ChevronDownIcon width={14} height={14} />
                      </IconButton>
                      <IconButton
                        size="small"
                        sx={{ p: 0.25 }}
                        onClick={(e) =>
                          setState({ day: day <= 1 ? 1 : Number(day) - 1 })
                        }
                      >
                        <ChevronDownIcon width={14} height={14} />
                      </IconButton>
                    </InputAdornment>
                  }
                  number
                  error={errors.determination}
                  hideHelperText
                />
                <CustomTextInput
                  startAdornment={
                    <Box
                      sx={{
                        height: 40.13,
                        borderRight: `1px solid ${silverSageColor}`,
                        pr: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: 12, whiteSpace: "unset" }}>
                        تعداد دفعات
                      </Typography>
                    </Box>
                  }
                  sx={{ mt: "0!important" }}
                  value={count}
                  onChange={(e) => {
                    let value = e.target.value;
                    setState({
                      count: value.startsWith("0")
                        ? value.replace(/^0+/, "")
                        : value,
                    });
                  }}
                  endAdornment={
                    <InputAdornment
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "auto",
                      }}
                      position="end"
                    >
                      <IconButton
                        size="small"
                        sx={{ p: 0.25, transform: "rotate(180deg)" }}
                        onClick={(e) => setState({ count: Number(count) + 1 })}
                      >
                        <ChevronDownIcon width={14} height={14} />
                      </IconButton>
                      <IconButton
                        size="small"
                        sx={{ p: 0.25 }}
                        onClick={(e) =>
                          setState({
                            count: count === 1 ? 1 : Number(count) - 1,
                          })
                        }
                      >
                        <ChevronDownIcon width={14} height={14} />
                      </IconButton>
                    </InputAdornment>
                  }
                  number
                  error={errors.determination}
                  hideHelperText
                />
              </Box>
              <Typography sx={{ color: errorColor, fontSize: 12, mt: 1 }}>
                {errors.determination}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                my: 1,
              }}
            >
              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                نمایش اطلاعات تماس
              </Typography>
              <MaterialUISwitch
                onChange={(e) =>
                  setState({ showContactInformation: e.target.checked })
                }
                checked={showContactInformation}
                //   onChange={() => setLimitPay((prevState) => !prevState)}
              />
            </Box>
          </>
        );
      case "ReceiptPage":
        return (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                my: 1,
              }}
            >
              <Typography sx={{ fontSize: 14 }}>
                نمایش صفحه تاییدیه پرداخت
              </Typography>
              <MaterialUISwitch
                checked={confirmationPage}
                onChange={(e) =>
                  setState({
                    confirmationPage: e.target.checked,
                    customConfirmationPage: false,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                top: confirmationPage ? 0 : -70,
                visibility: confirmationPage ? "visible" : "hidden",
                opacity: confirmationPage ? 1 : 0,
                height: confirmationPage ? 95.42 : 0,
                transition: "0.6s all",
              }}
            >
              <CustomTextInput
                label="پیام موفقیت دلخواه ( اختیاری )"
                placeholder="متن پیام دلخواه خود را وارد کنید"
                onChange={(e) =>
                  setState({ successfulMessage: e.target.value })
                }
                value={successfulMessage}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                top: confirmationPage ? 0 : -70,
                visibility: confirmationPage ? "visible" : "hidden",
                opacity: confirmationPage ? 1 : 0,
                height: confirmationPage ? 95.42 : 0,
                transition: "0.6s all",
              }}
            >
              <CustomTextInput
                label="پیام تراکنش ناموفق دلخواه ( اختیاری )"
                placeholder="متن پیام دلخواه خود را وارد کنید"
                onChange={(e) =>
                  setState({ unsuccessfulMessage: e.target.value })
                }
                value={unsuccessfulMessage}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 2,
                mb: 1,
              }}
            >
              <Typography sx={{ fontSize: 14 }}>
                نمایش صفحه دلخواه بعد از پرداخت
              </Typography>
              <MaterialUISwitch
                checked={customConfirmationPage}
                onChange={(e) =>
                  setState({
                    customConfirmationPage: e.target.checked,
                    confirmationPage: false,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                top: customConfirmationPage ? 0 : -70,
                visibility: customConfirmationPage ? "visible" : "hidden",
                opacity: customConfirmationPage ? 1 : 0,
                height: customConfirmationPage ? 95.42 : 0,
                transition: "0.6s all",
                mb: 1,
              }}
            >
              <CustomTextInput
                label="لینک تراکنش موفق"
                // placeholder="متن پیام دلخواه خود را وارد کنید"
                onChange={(e) =>
                  setState({
                    successfulLink: e.target.value,
                    errors: { ...errors, successfulLink: "" },
                  })
                }
                startAdornment={
                  <InputAdornment position="start">
                    <LinkIcon1 color={slateGrayColor} />
                  </InputAdornment>
                }
                error={errors.successfulLink}
                helperText={errors.successfulLink}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                top: customConfirmationPage ? 0 : -70,
                visibility: customConfirmationPage ? "visible" : "hidden",
                opacity: customConfirmationPage ? 1 : 0,
                height: customConfirmationPage ? 95.42 : 0,
                transition: "0.6s all",
              }}
            >
              <CustomTextInput
                label="لینک تراکنش ناموفق"
                // placeholder="متن پیام دلخواه خود را وارد کنید"
                onChange={(e) =>
                  setState({
                    unsuccessfulLink: e.target.value,
                    errors: { ...errors, unsuccessfulLink: "" },
                  })
                }
                startAdornment={
                  <InputAdornment position="start">
                    <LinkIcon1 color={slateGrayColor} />
                  </InputAdornment>
                }
                error={errors.unsuccessfulLink}
                helperText={errors.unsuccessfulLink}
              />
            </Box>
          </>
        );
    }
  };

  return (
    <>
      {renderTab()}
      <AddProduct
        open={open}
        setOpen={setOpen}
        onAdd={({ formData, setOpen }) =>
          dispatch(
            createProductRequestAction({
              formData,
              setOpen,
              onSuccess: (data) => {
                setState({ products: [...products, data] });
              },
            })
          )
        }
      />
    </>
  );
};

export default ProductConfig;

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  //   "& .MuiTabs-indicator": {
  //     backgroundColor: "#1890ff",
  //   },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    padding: "10px 8px",
    fontSize: 12,
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    // fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    // "&:hover": {
    //   color: "#40a9ff",
    //   opacity: 1,
    // },
    "&.Mui-selected": {
      //   color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
