import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Register from "./pages/Authentication/Registration";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import Loading from "./components/Loading";
import Lottie from "react-lottie";
import lottie1 from "./assets/lotties/logoMotion.json";
import lottie2 from "./assets/lotties/logoLoading.json";
import { Box } from "@mui/material";
import { FORGOT_PASSWORD, LOGIN, REGISTER } from "./pages/routes";


// Lazy-loaded components
const Index = lazy(() => import("./pages"));
const Login = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("./pages/Authentication/Login")), 500);
    })
);

function App() {
  const { user, token } = useSelector((state) => ({
    user: state.auth.user,
    token: state.auth.token,
  }));
  const [showFirstLottie, setShowFirstLottie] = useState(true);
  const [showSecondLottie, setShowSecondLottie] = useState(false);
  const [loadingCompleted, setLoadingCompleted] = useState(false);

  useEffect(() => {
    if (!showFirstLottie && !loadingCompleted) {
      // Show second Lottie for 2 seconds
      setShowSecondLottie(true);
      const timer = setTimeout(() => {
        setShowSecondLottie(false);
        setLoadingCompleted(true); // Mark the loading process as done
      }, 2000);
      return () => clearTimeout(timer); // Cleanup
    }
  }, [showFirstLottie, loadingCompleted]);

  const lottieOptions1 = {
    loop: false,
    autoplay: true,
    animationData: lottie1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const lottieOptions2 = {
    loop: false,
    autoplay: true,
    animationData: lottie2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Define routes
  const authRoutes = (
    <Routes>
      <Route path={LOGIN} element={<Login />} />
      <Route path={REGISTER} element={<Register />} />
      <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path="*" element={<Navigate to={LOGIN} />} />
    </Routes>
  );

  return loadingCompleted ? (
    <Suspense fallback={<Loading />}>{token ? <Index /> : authRoutes}</Suspense>
  ) : (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        zIndex: 10000,
        // backgroundColor: whiteColor,
        // visibility: loading ? "visible" : "hidden",
        // opacity: loading ? 1 : 0,
        transition: "all .5s ease-out",
      }}
    >
      {showFirstLottie && (
        <Lottie
          options={lottieOptions1}
          eventListeners={[
            {
              eventName: "complete", // Triggered when the animation ends
              callback: () => setShowFirstLottie(false),
            },
          ]}
        />
      )}
      {showSecondLottie && <Lottie options={lottieOptions2} />}
    </Box>
  );
}

export default App;
