import { PAYLINKS_LIST } from "../../pages/routes";
import {
  cancelPersonalPaylinkAPI,
  cancelProductPaylinkAPI,
  createPersonalPaylinkAPI,
  createProductPaylinkAPI,
  getPersonalPaylinksAPI,
  getProductPaylinksAPI,
} from "../../services/api/paylink";
import {
  CANCEL_PERSONAL_PAYLINK_FAILURE,
  CANCEL_PERSONAL_PAYLINK_STARTED,
  CANCEL_PERSONAL_PAYLINK_SUCCESS,
  CANCEL_PRODUCT_PAYLINK_FAILURE,
  CANCEL_PRODUCT_PAYLINK_STARTED,
  CANCEL_PRODUCT_PAYLINK_SUCCESS,
  CREATE_PERSONAL_PAYLINK_FAILURE,
  CREATE_PERSONAL_PAYLINK_STARTED,
  CREATE_PERSONAL_PAYLINK_SUCCESS,
  CREATE_PRODUCT_PAYLINK_FAILURE,
  CREATE_PRODUCT_PAYLINK_STARTED,
  CREATE_PRODUCT_PAYLINK_SUCCESS,
  GET_PERSONAL_PAYLINKS_FAILURE,
  GET_PERSONAL_PAYLINKS_STARTED,
  GET_PERSONAL_PAYLINKS_SUCCESS,
  GET_PRODUCT_PAYLINKS_FAILURE,
  GET_PRODUCT_PAYLINKS_STARTED,
  GET_PRODUCT_PAYLINKS_SUCCESS,
} from "../actionTypes";
// --------------------------------------------------------------------------------
export const getPersonalPaylinksRequestAction = () => {
  return async (dispatch) => {
    dispatch(getPersonalPaylinksStartedAction());
    try {
      let response = await getPersonalPaylinksAPI();
      if (response.status === "success") {
        dispatch(
          getPersonalPaylinksSuccessAction({ personalPaylinks: response.data })
        );
      } else {
        dispatch(getPersonalPaylinksFailureAction(response.data));
      }
    } catch (error) {
      dispatch(getPersonalPaylinksFailureAction(error.message));
    }
  };
};

export const getPersonalPaylinksStartedAction = () => ({
  type: GET_PERSONAL_PAYLINKS_STARTED,
});

export const getPersonalPaylinksSuccessAction = (payload) => ({
  type: GET_PERSONAL_PAYLINKS_SUCCESS,
  payload,
});

export const getPersonalPaylinksFailureAction = (message) => ({
  type: GET_PERSONAL_PAYLINKS_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const createPersonalPaylinkRequestAction = ({ data, navigate }) => {
  return async (dispatch) => {
    dispatch(createPersonalPaylinkStartedAction());
    try {
      let response = await createPersonalPaylinkAPI({ data });
      if (response.status === "success") {
        dispatch(createPersonalPaylinkSuccessAction());
        navigate(PAYLINKS_LIST, {
          state: { showSuccessfullyMessage: true },
        });
      } else {
        dispatch(createPersonalPaylinkFailureAction(response.data));
      }
    } catch (error) {
      dispatch(createPersonalPaylinkFailureAction(error.message));
    }
  };
};

export const createPersonalPaylinkStartedAction = () => ({
  type: CREATE_PERSONAL_PAYLINK_STARTED,
});

export const createPersonalPaylinkSuccessAction = () => ({
  type: CREATE_PERSONAL_PAYLINK_SUCCESS,
});

export const createPersonalPaylinkFailureAction = (message) => ({
  type: CREATE_PERSONAL_PAYLINK_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const cancelPersonalPaylinkRequestAction = ({ id }) => {
  return async (dispatch) => {
    dispatch(cancelPersonalPaylinkStartedAction());
    try {
      let response = await cancelPersonalPaylinkAPI({ id });
      if (response.status === "success") {
        dispatch(cancelPersonalPaylinkSuccessAction());
        dispatch(getPersonalPaylinksRequestAction());
      } else {
        dispatch(cancelPersonalPaylinkFailureAction(response.data));
      }
    } catch (error) {
      dispatch(cancelPersonalPaylinkFailureAction(error.message));
    }
  };
};

export const cancelPersonalPaylinkStartedAction = () => ({
  type: CANCEL_PERSONAL_PAYLINK_STARTED,
});

export const cancelPersonalPaylinkSuccessAction = (payload) => ({
  type: CANCEL_PERSONAL_PAYLINK_SUCCESS,
  payload,
});

export const cancelPersonalPaylinkFailureAction = (message) => ({
  type: CANCEL_PERSONAL_PAYLINK_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getProductPaylinksRequestAction = () => {
  return async (dispatch) => {
    dispatch(getProductPaylinksStartedAction());
    try {
      let response = await getProductPaylinksAPI();
      if (response.status === "success") {
        const productPaylinks = await Promise.all(
          response.data.map(async (p) => ({
            ...p,
            products: await Promise.all(
              p.products.map(async (i) => {
                try {
                  const res = await fetch(i.image);
                  const blob = await res.blob();
                  return { ...i, image: URL.createObjectURL(blob) };
                } catch (error) {
                  console.error("Error fetching image:", error);
                  return { ...i, image: null };
                }
              })
            ),
          }))
        );
        dispatch(getProductPaylinksSuccessAction({ productPaylinks }));
      } else {
        dispatch(getProductPaylinksFailureAction(response.data));
      }
    } catch (error) {
      dispatch(getProductPaylinksFailureAction(error.message));
    }
  };
};

export const getProductPaylinksStartedAction = () => ({
  type: GET_PRODUCT_PAYLINKS_STARTED,
});

export const getProductPaylinksSuccessAction = (payload) => ({
  type: GET_PRODUCT_PAYLINKS_SUCCESS,
  payload,
});

export const getProductPaylinksFailureAction = (message) => ({
  type: GET_PRODUCT_PAYLINKS_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const createProductPaylinkRequestAction = ({ data, navigate }) => {
  return async (dispatch) => {
    dispatch(createProductPaylinkStartedAction());
    try {
      let response = await createProductPaylinkAPI({ data });
      if (response.status === "success") {
        dispatch(createProductPaylinkSuccessAction());
        navigate(PAYLINKS_LIST, {
          state: { showSuccessfullyMessage: true },
        });
      } else {
        dispatch(createProductPaylinkFailureAction(response.data));
      }
    } catch (error) {
      dispatch(createProductPaylinkFailureAction(error.message));
    }
  };
};

export const createProductPaylinkStartedAction = () => ({
  type: CREATE_PRODUCT_PAYLINK_STARTED,
});

export const createProductPaylinkSuccessAction = (payload) => ({
  type: CREATE_PRODUCT_PAYLINK_SUCCESS,
  payload,
});

export const createProductPaylinkFailureAction = (message) => ({
  type: CREATE_PRODUCT_PAYLINK_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const cancelProductPaylinkRequestAction = ({ id }) => {
  return async (dispatch) => {
    dispatch(cancelProductPaylinkStartedAction());
    try {
      let response = await cancelProductPaylinkAPI({ id });
      if (response.status === "success") {
        dispatch(cancelProductPaylinkSuccessAction());
        dispatch(getProductPaylinksRequestAction());
      } else {
        dispatch(cancelProductPaylinkFailureAction(response.data));
      }
    } catch (error) {
      dispatch(cancelProductPaylinkFailureAction(error.message));
    }
  };
};

export const cancelProductPaylinkStartedAction = () => ({
  type: CANCEL_PRODUCT_PAYLINK_STARTED,
});

export const cancelProductPaylinkSuccessAction = (payload) => ({
  type: CANCEL_PRODUCT_PAYLINK_SUCCESS,
  payload,
});

export const cancelProductPaylinkFailureAction = (message) => ({
  type: CANCEL_PRODUCT_PAYLINK_FAILURE,
  payload: message,
});
