import axiosMiddleware from "./axiosMiddleware";
// --------------------------------------------------------------------------------
export const getDashboardDataAPI = () => {
  return axiosMiddleware({
    method: "post",
    url: "dashboard",
  });
};
export const updateUserStepAPI = ({ paylink, webgate }) => {
  let postData = {
    paylink,
    webgate,
  };
  return axiosMiddleware({
    method: "post",
    url: "update-service-request",
    data: postData,
  });
};
export const getAnnouncementsAPI = () => {
  return axiosMiddleware({
    method: "post",
    url: "announcement/allAnnouncement",
  });
};
export const seenAnnouncementAPI = ({ announcementsId }) => {
  let postData = {
    announcement_id: announcementsId,
  };
  return axiosMiddleware({
    method: "post",
    url: "announcement/set",
    data: postData,
  });
};
export const getBusinessCategoriesAPI = () => {
  return axiosMiddleware({
    method: "post",
    url: "user/get-business-category",
  });
};
export const setBusinessCategoryAPI = ({
  businessCategoryId,
  businessCategoryName,
}) => {
  let postData = {
    business_category_id: businessCategoryId,
    business_category_name: businessCategoryName,
  };
  return axiosMiddleware({
    method: "post",
    url: "user/set-business-category",
    data: postData,
  });
};
export const getPollLinkAPI = () => {
  return axiosMiddleware({
    method: "post",
    url: "get-modal",
  });
};
export const changePollStatusAPI = ({ status }) => {
  let postData = {
    status,
  };
  return axiosMiddleware({
    method: "post",
    url: "change-modal-show",
    data: postData,
  });
};
