import { Box, Chip, IconButton, Typography } from "@mui/material";
import MuiStepper from "../../components/Stepper";
import {
  CheckIcon,
  EyeIcon,
  InfoIcon,
  KeyIcon,
  Simplification,
} from "../../assets/icons";
import {
  aquaMist,
  arcticMistColor,
  errorColor,
  primaryColor,
  silverSageColor,
  slateGrayColor,
  whiteColor,
} from "../../assets/colors";
import { createElement, useEffect, useState } from "react";
import { formatTime } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import CustomTextInput from "../../components/CustomTextInput";
import {
  createUserPasswordRequestAction,
  sendUserEmailRequestAction,
  verifyUserEmailRequestAction,
} from "../../redux/actions/userActions";
import CustomOtpInput from "../../components/CustomOtpInput";
import Button from "../../components/Button";
import {
  DIGIT_REGEX,
  EMAIL_REGEX,
  LOWERCASE_LETTERS_REGEX,
  SPECIAL_CHARACTERS_REGEX,
  UPPERCASE_LETTERS_REGEX,
} from "../../utils/regex";
import { useNavigate } from "react-router-dom";
import MobileStepper from "../../components/MobileStepper";
import { SEND_USER_EMAIL_STARTED } from "../../redux/actionTypes";
import { DASHBOARD } from "../routes";
// --------------------------------------------------------------------------------
const EmailConfirmation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { rType } = useSelector((state) => state.user);
  const initialState = {
    name: "",
    registrationNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    token: "",
    showTokenInput: false,
    activeStep: 0,
    errors: {},
  };
  const [state, setInitState] = useState(initialState);
  const [counter, setCounter] = useState(0);
  const steps = [
    { label: "تایید ایمیل کاربری", value: 0, icon: Simplification },
    {
      label: "ایجاد رمز عبور برای حساب",
      value: 1,
      icon: KeyIcon,
    },
    {
      label: "حساب شما آماده استفاده است!",
      value: 2,
      icon: CheckIcon,
    },
  ];

  useEffect(() => {
    let timer = null;
    timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const setState = (state) => {
    setInitState((prevState) => ({ ...prevState, ...state }));
  };

  const handleSendEmail = (e) => {
    e.preventDefault();
    const errorObj = {};

    if (!EMAIL_REGEX.test(state.email))
      errorObj.email = "آدرس ایمیل صحیح نمی باشد.";
    if (state.email === "") errorObj.email = "لطفا آدرس ایمیل را وارد کنید.";

    setState({ errors: errorObj });

    if (Object.keys(errorObj).length > 0) return;

    dispatch(
      sendUserEmailRequestAction({ email: state.email, setState, setCounter })
    );
  };

  const handleVerifyEmail = (e) => {
    e.preventDefault();
    const errorObj = {};

    if (state.token === "") errorObj.token = "لطفا کد ایمیل شده را وارد کنید.";

    setState({ errors: errorObj });

    if (Object.keys(errorObj).length > 0) return;

    dispatch(
      verifyUserEmailRequestAction({
        email: state.email,
        token: state.token,
        setState,
      })
    );
  };

  const handleCreatePassword = (e) => {
    e.preventDefault();
    const errorObj = {};

    if (state.password.length < 8) {
      errorObj.passwordLength = true;
    }
    if (!UPPERCASE_LETTERS_REGEX.test(state.password)) {
      errorObj.uppercaseLetter = true;
    }
    if (!LOWERCASE_LETTERS_REGEX.test(state.password)) {
      errorObj.lowercaseLetter = true;
    }
    if (!DIGIT_REGEX.test(state.password)) {
      errorObj.number = true;
    }
    if (!SPECIAL_CHARACTERS_REGEX.test(state.password)) {
      errorObj.specialCharacter = true;
    }
    if (state.confirmPassword !== state.password)
      errorObj.confirmPassword = "رمز عبور و تکرار آن مطابقت ندارند.";
    if (state.password === "")
      errorObj.password = "لطفا رمز عبور را وارد کنید.";
    if (state.confirmPassword === "")
      errorObj.confirmPassword = "لطفا تکرار رمز عبور را وارد کنید.";

    setState({ errors: errorObj });

    if (Object.keys(errorObj).length > 0) return;

    dispatch(
      createUserPasswordRequestAction({
        password: state.password,
        confirmPassword: state.confirmPassword,
        email: state.email,
        setState,
      })
    );
  };

  const renderSteps = () => {
    switch (state.activeStep) {
      case 0:
        return (
          <>
            <Box sx={{ flex: 1, px: { xs: 2, lg: 0 }, py: { xs: 3, lg: 0 } }}>
              <Typography
                sx={{
                  mb: "38px",
                  fontWeight: 700,
                  display: { xs: "none", lg: "block" },
                }}
              >
                تایید ایمیل کاربری
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CustomTextInput
                  label="ایمیل شما"
                  placeholder="etc@info.ir مثلا"
                  value={state.email}
                  onChange={(e) => setState({ email: e.target.value })}
                  helperText={state.errors.email}
                  error={state.errors.email}
                />
                <Chip
                  label={
                    counter > 0
                      ? `ارسال مجدد ${formatTime(counter)}`
                      : "ارسال کد تایید"
                  }
                  onClick={handleSendEmail}
                  sx={{ mt: 1, ml: 1 }}
                  color="emeraldOasis"
                  disabled={rType === SEND_USER_EMAIL_STARTED || counter > 0}
                />
              </Box>
              <CustomOtpInput
                label="ورود کد تایید"
                length={5}
                value={state.token}
                onComplete={(value) => setState({ token: value })}
                containerProps={{
                  sx: {
                    label: { fontSize: 14 },
                    visibility: state.showTokenInput ? "visible" : "hidden",
                    opacity: state.showTokenInput ? 1 : 0,
                    transition: "1s all",
                    "& .MuiInputBase-root": { mr: 1 },
                  },
                }}
                helperText={state.errors.token}
                error={state.errors.token}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                px: { xs: 2, lg: 0 },
                py: { xs: 3, lg: 0 },
              }}
            >
              <Button
                text="مرحله بعد"
                sx={{ borderRadius: { lg: 100 }, width: { lg: 181 } }}
                onClick={handleVerifyEmail}
                disabled={!state.showTokenInput}
              />
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <Box sx={{ flex: 1, px: { xs: 2, lg: 0 }, py: { xs: 3, lg: 0 } }}>
              <Typography
                sx={{
                  mb: "19px",
                  fontWeight: 700,
                  display: { xs: "none", lg: "block" },
                }}
              >
                ایجاد رمز عبور برای حساب
              </Typography>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  p: 1,
                  border: `1px solid ${silverSageColor}`,
                  borderRadius: "10px",
                  py: 2,
                  mb: "19px",
                  // display: "flex",
                }}
              >
                <Box sx={{ display: "flex", mb: 1 }}>
                  <InfoIcon
                    style={{
                      width: 20,
                      height: 20,
                      color: state.errors.passwordLength ? errorColor : "",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 12,
                      ml: 0.5,
                      lineHeight: 1.8,
                      flex: 1,
                      color: state.errors.passwordLength ? errorColor : "",
                    }}
                  >
                    لطفا توجه داشته باشید که پسورد شما باید حداقل ۸ کاراکتر
                    باشد.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <InfoIcon
                    style={{
                      width: 20,
                      height: 20,
                      color: state.errors.uppercaseLetter ? errorColor : "",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 12,
                      ml: 0.5,
                      lineHeight: 1.8,
                      flex: 1,
                      color: state.errors.uppercaseLetter ? errorColor : "",
                    }}
                  >
                    لطفا مطمئن شوید که پسورد شما حداقل یک حرف بزرگ (A-Z) دارد.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <InfoIcon
                    style={{
                      width: 20,
                      height: 20,
                      color: state.errors.passwordLength ? errorColor : "",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 12,
                      ml: 0.5,
                      lineHeight: 1.8,
                      flex: 1,
                      color: state.errors.lowercaseLetter ? errorColor : "",
                    }}
                  >
                    لطفا مطمئن شوید که پسورد شما حداقل یک حرف کوچک (a-z) شامل
                    می‌شود.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <InfoIcon
                    style={{
                      width: 20,
                      height: 20,
                      color: state.errors.number ? errorColor : "",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 12,
                      ml: 0.5,
                      lineHeight: 1.8,
                      flex: 1,
                      color: state.errors.number ? errorColor : "",
                    }}
                  >
                    لطفا اطمینان حاصل کنید پسورد شما شامل حداقل یک عدد باشد.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <InfoIcon
                    style={{
                      width: 20,
                      height: 20,
                      color: state.errors.specialCharacter ? errorColor : "",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 12,
                      ml: 0.5,
                      lineHeight: 1.8,
                      flex: 1,
                      color: state.errors.specialCharacter ? errorColor : "",
                    }}
                  >
                    برای امنیت بیشتر، پسورد شما شامل حداقل یک کاراکتر خاص (مانند
                    !، @، #، $ و غیره) باشد.
                  </Typography>
                </Box>
              </Box>
              <CustomTextInput
                label="رمز عبور جدید"
                type={state.showNewPassword ? "text" : "password"}
                placeholder="... رمز جدید را وارد کنید"
                value={state.password}
                onChange={(e) => setState({ password: e.target.value })}
                helperText={state.errors.password}
                error={state.errors.password}
                endAdornment={
                  <IconButton
                    onClick={() =>
                      setState({
                        showNewPassword: !state.showNewPassword,
                      })
                    }
                  >
                    <EyeIcon width={24} />
                  </IconButton>
                }
              />
              <CustomTextInput
                label="تکرار رمز عبور جدید"
                type={state.showConfirmNewPassword ? "text" : "password"}
                placeholder="... رمز جدید را وارد کنید"
                value={state.confirmPassword}
                onChange={(e) => setState({ confirmPassword: e.target.value })}
                helperText={state.errors.confirmPassword}
                error={state.errors.confirmPassword}
                endAdornment={
                  <IconButton
                    onClick={() =>
                      setState({
                        showConfirmNewPassword: !state.showConfirmNewPassword,
                      })
                    }
                  >
                    <EyeIcon width={24} />
                  </IconButton>
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                px: { xs: 2, lg: 0 },
                py: { xs: 3, lg: 0 },
              }}
            >
              <Button
                text="ثبت ایمیل و رمز عبور"
                sx={{ borderRadius: { lg: 100 }, width: { lg: 181 } }}
                onClick={handleCreatePassword}
              />
            </Box>
          </>
        );
      case 2:
        return (
          <>
            <Box sx={{ my: "auto", px: { xs: 2, lg: 0 }, py: { xs: 3 } }}>
              <Box
                sx={{
                  width: 85,
                  height: 85,
                  backgroundColor: aquaMist,
                  borderRadius: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mx: "auto",
                  mb: "21px",
                }}
              >
                <CheckIcon
                  style={{ color: primaryColor, width: 65.4, height: 65.4 }}
                />
              </Box>
              <Typography sx={{ fontWeight: 700, mb: 1, textAlign: "center" }}>
                ثبت ایمیل و رمز عبور با موفقیت انجام شد.
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: 1.8,
                  textAlign: "center",
                }}
              >
                از این پس امکان ورود به دشبورد کاربری با استفاده از ایمیل و رمز
                عبور نیز فراهم شده است.
              </Typography>
            </Box>
            <Box
              sx={{
                px: { xs: 2, lg: 0 },
                py: { xs: 3, lg: 0 },
              }}
            >
              <Button
                text="بازگشت به دشبورد"
                sx={{ borderRadius: { lg: 100 } }}
                onClick={() => navigate(DASHBOARD)}
              />
            </Box>
          </>
        );
    }
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: { xs: "flex", lg: "none" },
          flexDirection: "column",
          backgroundColor: whiteColor,
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundColor: arcticMistColor,
            px: "20px",
            display: { xs: "flex", lg: "none" },
            alignItems: "center",
            height: 70,
          }}
        >
          <Box
            sx={{
              width: 48,
              height: 48,
              backgroundColor: primaryColor,
              borderRadius: 100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mr: 1,
            }}
          >
            {createElement(steps[state.activeStep].icon, {
              color: whiteColor,
              width: 24,
              height: 24,
            })}
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 1 }}>
              {steps[state.activeStep].label}
            </Typography>
            <Typography sx={{ fontSize: 10, color: slateGrayColor }}>
              مرحله {state.activeStep + 1} از {steps.length}
            </Typography>
          </Box>
          <MobileStepper activeStep={state.activeStep} steps={steps} />
        </Box>
        {renderSteps()}
      </Box>
      <Box
        sx={{
          flex: 1,
          display: { xs: "none", lg: "flex" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            backgroundColor: whiteColor,
            px: "14px",
            py: "16px",
            borderRadius: "16px",
            width: 686,
            height: 589,
          }}
        >
          <Box
            sx={{
              ml: 1,
              flex: 1,
              mr: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {renderSteps()}
          </Box>
          <Box
            sx={{
              backgroundColor: arcticMistColor,
              border: `1px solid ${silverSageColor}`,
              borderRadius: "15px",
              // minHeight: 647 - 56,
              width: 254,
              px: "13px",
              py: "15px",
            }}
          >
            <MuiStepper activeStep={state.activeStep} steps={steps} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EmailConfirmation;
