import {
  Box,
  Button as MuiButton,
  Radio,
  Typography,
  styled,
  Tab,
  Tabs,
  Dialog,
  DialogContent,
  DialogTitle,
  ButtonBase,
  DialogActions,
  SwipeableDrawer,
} from "@mui/material";
import {
  aquaMist,
  arcticMistColor,
  blackColor,
  deepBlue,
  forestShadowColor,
  primaryColor,
  silverSageColor,
  slateGrayColor,
  whiteColor,
} from "../../assets/colors";
import {
  ArrowLeftIcon,
  CalendarIcon,
  CheckIcon,
  EuroIcon,
  RocketIcon,
} from "../../assets/icons";
import { useEffect, useState } from "react";
import Button from "../../components/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../../components/Title";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlansHistoryRequestAction,
  upgradePlanRequestAction,
} from "../../redux/actions/userActions";
import moment from "moment-jalaali";
import {
  CREATE_TICKET_STARTED,
  UPGRADE_PLAN_STARTED,
} from "../../redux/actionTypes";
import { useNavigate } from "react-router-dom";
import { appBarHeight, drawerWidth } from "../../utils/constants";
import { getVirtualAccountsRequestAction } from "../../redux/actions/virtualAccountsActions";
import { createTicketRequestAction } from "./../../redux/actions/ticketActions";
import { PRICING } from "../routes";
import { periods, subscriptionAmount } from "./constants";

const Plan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    auth: { user },
    user: { plansHistory, rType },
    euroBalance,
    ticket,
  } = useSelector((state) => ({
    auth: state.auth,
    user: state.user,
    euroBalance: state.virtualAccounts.virtualAccounts[0]?.balance,
    ticket: state.ticket,
  }));
  const [recieptData, setRecieptData] = useState({});

  const initialState = {
    selectedPlan: {},
    openPersonalPlanConfirm: false,
    openPayConfirm: false,
    duration: 1,
    payStep: 0,
  };

  const [
    {
      selectedPlan,
      openPersonalPlanConfirm,
      openPayConfirm,
      duration,
      payStep,
    },
    setInitState,
  ] = useState(initialState);

  const open = openPayConfirm || openPersonalPlanConfirm;

  useEffect(() => {
    dispatch(getPlansHistoryRequestAction());
    dispatch(getVirtualAccountsRequestAction());
  }, []);

  const setState = (state) => {
    setInitState((prevState) => ({ ...prevState, ...state }));
  };

  const handleChange = (event, newValue) => {
    // setTab(newValue);
    // setPlanId(false);
    // setCardIndex(0);
    setState({ duration: newValue, selectedPlan: {} });
  };

  const renderPayConfirm = () => {
    switch (payStep) {
      case 0:
        return (
          <>
            <DialogTitle sx={{ pt: "33px", pb: "28px" }}>
              <Box
                sx={{
                  height: 48,
                  backgroundColor: primaryColor,
                  width: 160,
                  borderTopLeftRadius: 100,
                  borderBottomLeftRadius: 100,
                  position: "absolute",
                  top: 28,
                  right: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{ fontSize: 22.58, fontWeight: 700, color: whiteColor }}
                >
                  <Typography
                    sx={{
                      fontSize: 14.11,
                      display: "inline-block",
                      color: whiteColor,
                      fontWeight: 399,
                    }}
                  >
                    {periods[duration]}/
                  </Typography>
                  {selectedPlan.price} €
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    backgroundColor: arcticMistColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 100,
                  }}
                >
                  <RocketIcon />
                </Box>
                <Typography sx={{ ml: 1, fontWeight: 600, fontSize: 18 }}>
                  خرید پلن {selectedPlan.name}
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  height: 55,
                  border: `1px solid ${silverSageColor}`,
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  mb: "20px",
                }}
              >
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    backgroundColor: deepBlue,
                    borderRadius: 100,
                    //   display: "flex",
                    //   justifyContent: "center",
                    //   alignItems: "center",
                  }}
                >
                  <EuroIcon
                    width={24}
                    height={24}
                    color={whiteColor}
                    style={{ marginRight: 3, marginTop: 2.5 }}
                  />
                </Box>
                <Typography sx={{ fontSize: 12, ml: 1, mr: "auto" }}>
                  موجودی شما
                </Typography>
                <Typography>{euroBalance}</Typography>
                <EuroIcon
                  width={18}
                  height={18}
                  style={{ marginBottom: "4px" }}
                />
              </Box>
              <Typography sx={{ fontWeight: 600, mb: "20px" }}>
                انتخاب روش پرداخت
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: `1px solid ${silverSageColor}`,
                  height: 73,
                  px: "19px",
                  borderRadius: "8px",
                  mb: "12px",
                  cursor: "pointer",
                  transition: "all .5s",
                  textAlign: "left",
                  width: "100%",
                  ":hover": {
                    borderColor: primaryColor,
                  },
                  ":disabled": {
                    opacity: 0.45,
                  },
                }}
                component={ButtonBase}
                onClick={() => setState({ payStep: 1 })}
                disabled={Number(euroBalance) < Number(selectedPlan.price)}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      // color: primaryColor,
                      mb: "12px",
                    }}
                  >
                    پرداخت از موجودی حساب
                  </Typography>
                  {Number(euroBalance) < Number(selectedPlan.price) ? (
                    <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                      موجودی کافی نیست!
                    </Typography>
                  ) : (
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          color: slateGrayColor,
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                      >
                        موجودی شما: {euroBalance}
                      </Typography>
                      <EuroIcon width={16} height={16} color={slateGrayColor} />
                    </Box>
                  )}
                </Box>
                <ArrowLeftIcon width={24} height={24} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: `1px solid ${silverSageColor}`,
                  height: 73,
                  px: "19px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  transition: "all .5s",
                  textAlign: "left",
                  width: "100%",
                  ":hover": {
                    borderColor: primaryColor,
                  },
                }}
                component={ButtonBase}
                onClick={() => {
                  dispatch(
                    createTicketRequestAction({
                      data: {
                        lang: "fa",
                        source: "Website",
                        subject: "خرید اشتراک",
                        text: `<p>با سلام</br>لطفا جهت خرید اشتراک ${selectedPlan.name} ${periods[duration]} شماره حساب و مبلغ را ارسال نمایید.</br>با تشکر</p>`,
                        category_id: 6,
                      },
                      navigate,
                    })
                  );
                }}
                disabled={ticket.rType == CREATE_TICKET_STARTED}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      // color: primaryColor,
                      mb: "12px",
                    }}
                  >
                    پرداخت به حساب بانکی
                  </Typography>
                  <Typography
                    sx={{
                      color: slateGrayColor,
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    شارژ حساب از طریق ثبت تیکت به واحد مالی
                  </Typography>
                </Box>
                <ArrowLeftIcon width={24} height={24} />
              </Box>
            </DialogContent>
          </>
        );
      case 1:
        return (
          <>
            <DialogContent sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  width: 89,
                  height: 89,
                  backgroundColor: arcticMistColor,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  mx: "auto",
                  mb: "21px",
                }}
              >
                <RocketIcon width={53.4} height={53.4} />
              </Box>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  width: 308,
                  lineHeight: 2,
                  mx: "auto",
                  mb: "21px",
                }}
              >
                آیا میخواهید اشتراک {} {selectedPlan.name} را تهیه کنید؟
              </Typography>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  height: 55,
                  border: `1px solid ${silverSageColor}`,
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  mb: "13px",
                }}
              >
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    backgroundColor: deepBlue,
                    borderRadius: 100,
                    //   display: "flex",
                    //   justifyContent: "center",
                    //   alignItems: "center",
                  }}
                >
                  <EuroIcon
                    width={24}
                    height={24}
                    color={whiteColor}
                    style={{ marginRight: 3, marginTop: 2.5 }}
                  />
                </Box>
                <Typography sx={{ fontSize: 12, ml: 1, mr: "auto" }}>
                  موجودی شما
                </Typography>
                <Typography>{euroBalance}</Typography>
                <EuroIcon width={18} height={18} />
              </Box>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  height: 55,
                  border: `1px solid ${silverSageColor}`,
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  // mb: "20px",
                }}
              >
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RocketIcon width={23.4} height={23.4} />
                </Box>
                <Typography sx={{ fontSize: 12, ml: 1, mr: "auto" }}>
                  هزینه اشتراک
                </Typography>
                <Typography>{selectedPlan.price}</Typography>
                <EuroIcon width={18} height={18} />
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "space-between",
                px: "24px",
                pb: "38px",
                pt: "17px",
              }}
            >
              <Button
                sx={{ width: 131, borderRadius: 100, color: slateGrayColor }}
                text="مرحله قبل"
                color="silverSage"
                variant="outlined"
                onClick={() => setState({ payStep: 0 })}
              />
              <Button
                sx={{ width: 253, borderRadius: 100 }}
                text="تایید و پرداخت"
                onClick={() =>
                  dispatch(
                    upgradePlanRequestAction({
                      setState,
                      setRecieptData,
                      planId: selectedPlan.id,
                      duration,
                    })
                  )
                }
                loading={rType === UPGRADE_PLAN_STARTED}
              />
            </DialogActions>
          </>
        );
      case 2:
        return (
          <>
            <DialogContent sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  width: 87,
                  height: 87,
                  backgroundColor: aquaMist,
                  borderRadius: 100,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mx: "auto",
                  mb: "22px",
                }}
              >
                <CheckIcon width={50} height={87} color={primaryColor} />
              </Box>
              <Typography sx={{ fontWeight: 700, mb: "31px" }}>
                اشتراک شما با موفقیت فعال شد!
              </Typography>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  height: 55,
                  // border: `1px solid ${silverSageColor}`,
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  // mb: "20px",
                }}
              >
                <Typography sx={{ fontSize: 13, fontWeight: 600, mr: "auto" }}>
                  هزینه اشتراک
                </Typography>
                <Typography sx={{ fontSize: 14.54, fontWeight: 700 }}>
                  {recieptData.amount}
                </Typography>
                <EuroIcon width={18} height={18} />
              </Box>
              <Box
                sx={{
                  height: 55,
                  // border: `1px solid ${silverSageColor}`,
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  // mb: "20px",
                }}
              >
                <Typography sx={{ fontSize: 13, fontWeight: 600, mr: "auto" }}>
                  تاریخ شروع اشتراک
                </Typography>
                <Typography sx={{ fontSize: 14.54, fontWeight: 700 }}>
                  {moment(recieptData.start_date).format("jYYYY/jMM/jDD")}
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  height: 55,
                  // border: `1px solid ${silverSageColor}`,
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  px: 1,
                  // mb: "20px",
                }}
              >
                <Typography sx={{ fontSize: 13, fontWeight: 600, mr: "auto" }}>
                  تاریخ پایان اشتراک
                </Typography>
                <Typography sx={{ fontSize: 14.54, fontWeight: 700 }}>
                  {moment(recieptData.end_date).format("jYYYY/jMM/jDD")}
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions sx={{ px: "27px", pb: "40px" }}>
              <Button
                text="تایید و بستن"
                sx={{ borderRadius: 100 }}
                onClick={() =>
                  setState({
                    openPayConfirm: false,
                    selectedPlan: {},
                    payStep: 0,
                  })
                }
              />
            </DialogActions>
          </>
        );
      default:
        break;
    }
  };

  const renderPersonalPlanConfirm = () => (
    <>
      <DialogContent sx={{ textAlign: "center" }}>
        <Box
          sx={{
            width: 89,
            height: 89,
            backgroundColor: arcticMistColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 100,
            mx: "auto",
            mb: "21px",
          }}
        >
          <RocketIcon width={53.4} height={53.4} />
        </Box>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 600,
            // width: 308,
            lineHeight: 2,
            mx: "auto",
            mb: "21px",
          }}
        >
          آیا میخواهید اشتراک شخصی را انتخاب کنید؟
        </Typography>

        <Box
          sx={{
            backgroundColor: arcticMistColor,
            height: 55,
            border: `1px solid ${silverSageColor}`,
            borderRadius: "12px",
            display: "flex",
            alignItems: "center",
            px: 1,
            // mb: "20px",
          }}
        >
          <Typography sx={{ fontSize: 12, ml: 1, mr: "auto" }}>
            هزینه اشتراک
          </Typography>
          <Typography>{selectedPlan.price}</Typography>
          {/* <EuroIcon width={18} height={18} /> */}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          px: "24px",
          pb: "38px",
          pt: "17px",
        }}
      >
        <Button
          sx={{ width: 131, borderRadius: 100, color: slateGrayColor }}
          text="بستن"
          color="silverSage"
          variant="outlined"
          onClick={() => setState({ openPersonalPlanConfirm: false })}
        />
        <Button
          sx={{ borderRadius: 100, width: 253 }}
          text="تایید"
          onClick={() =>
            dispatch(
              upgradePlanRequestAction({
                setState,
                setRecieptData,
                planId: selectedPlan.id,
                duration,
              })
            )
          }
          loading={rType === UPGRADE_PLAN_STARTED}
        />
      </DialogActions>
    </>
  );
  const dialogContent = openPayConfirm
    ? renderPayConfirm()
    : renderPersonalPlanConfirm();

  const renderDialog = (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          borderRadius: 5,
          boxShadow: "0px 0px 77px 20px #5F5F5F08",
          width: 452,
        },
      }}
      BackdropProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(9px)",
          left: { lg: drawerWidth },
          top: appBarHeight,
        },
      }}
      sx={{
        left: { lg: drawerWidth },
        top: appBarHeight,
        display: { xs: "none", lg: "block" },
      }}
      onClose={() =>
        setState({
          openPayConfirm: false,
          openPersonalPlanConfirm: false,
          payStep: 0,
        })
      }
      scroll="body"
    >
      {dialogContent}
    </Dialog>
  );

  const renderDrawer = (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      // onOpen={() => setOpen(true)}
      disableSwipeToOpen
      onClose={() =>
        setState({
          openPayConfirm: false,
          openPersonalPlanConfirm: false,
          payStep: 0,
        })
      }
      PaperProps={{
        sx: {
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        },
      }}
      BackdropProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(9px)",
          top: appBarHeight,
        },
      }}
      sx={{ zIndex: 9999, display: { lg: "none" } }}
      ModalProps={{
        keepMounted: false,
      }}
    >
      {dialogContent}
    </SwipeableDrawer>
  );

  return (
    <Box sx={{ flex: 1, p: 2.25 }}>
      <Title
        text="ارتقا اشتراک"
        size={4}
        containerProps={{
          sx: { display: { xs: "none", lg: "flex" }, mb: 1.5 },
        }}
      />
      <Box sx={{ mb: 4 }}>
        <Box
          sx={{
            backgroundColor: arcticMistColor,
            p: 2,
            borderRadius: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box
                sx={{
                  width: 5,
                  height: 5,
                  backgroundColor: primaryColor,
                  borderRadius: 100,
                  mr: 1,
                }}
              ></Box>
              <Typography sx={{ fontSize: 14, fontWeight: 600, mr: 1 }}>
                اشتراک فعال:
              </Typography>
              <Typography>{user.plan_name}</Typography>
            </Box>
            {user.remaining_days ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: "13px",
                }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: 600, mr: 1 }}>
                  باقی مانده:
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  {user.remaining_days} روز
                </Typography>
              </Box>
            ) : null}
          </Box>
          <Box
            sx={{
              backgroundColor: "#0000000D",
              width: 180,
              height: 180,
              borderRadius: 100,
              position: "absolute",
              top: -10,
              right: -60,
            }}
          ></Box>
          <CalendarIcon />
        </Box>
      </Box>
      <Box sx={{ display: { lg: "none" } }}>
        <Title text="ارتقا اشتراک" size={4} />
      </Box>
      <Box sx={{ display: "flex" }}>
        <StyledTabs
          value={duration}
          onChange={handleChange}
          sx={{ mx: "auto" }}
        >
          <StyledTab label="ماهیانه" value={1} />
          <StyledTab label="6 ماهه" value={6} />
          <StyledTab label="سالیانه" value={12} />
        </StyledTabs>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: "35px",
          rowGap: "18px",
        }}
      >
        <PlanButton
          isSelected={selectedPlan.id === 1}
          title="شخصی"
          title2="رایگان!"
          desc="مناسب برای افرادی که توانایی فروش محصولات یا خدمات به خارج از کشور را دارند."
          onClick={() => {
            setState({
              selectedPlan: {
                name: "شخصی",
                price: "رایگان!",
                id: 1,
              },
            });
          }}
          disabled={user.plan_id === 1}
          sx={{ flex: { lg: "45%" } }}
        />
        <PlanButton
          isSelected={selectedPlan.id === 2}
          title="کسب و کار"
          desc="مناسب برای کسب‌وکارهایی که وب‌سایت دارند و محصولات یا خدمات خود را به خارج از کشور می‌فروشند."
          title2={`€ ${subscriptionAmount["Business"][duration]}`}
          title3="ماهیانه/"
          onClick={() => {
            setState({
              selectedPlan: {
                name: "کسب و کار",
                price: subscriptionAmount["Business"][duration],
                id: 2,
              },
            });
          }}
          disabled={user.plan_id === 2}
          sx={{ flex: { lg: "45%" } }}
        />
        <PlanButton
          isSelected={selectedPlan.id === 3}
          title="کسب و کار ویژه"
          title2={`€ ${subscriptionAmount["BusinessPlus"][duration]}`}
          title3="ماهیانه/"
          desc="مناسب برای کسب‌وکارهایی که بیش از یک وب‌سایت برای فروش محصولات یا خدمات خود به خارج از کشور دارند."
          onClick={() => {
            setState({
              selectedPlan: {
                name: "کسب و کار ویژه",
                price: subscriptionAmount["BusinessPlus"][duration],
                id: 3,
              },
            });
          }}
          disabled={user.plan_id === 3}
          sx={{ flex: { lg: "45%" } }}
        />
        <Box sx={{ flex: { lg: "45%" } }}></Box>
        <Box sx={{ flex: { lg: "45%" } }}></Box>
        <Button
          text={selectedPlan.id !== 1 ? "تایید و پرداخت" : "تایید"}
          onClick={() =>
            selectedPlan.id === 1
              ? setState({ openPersonalPlanConfirm: true })
              : setState({ openPayConfirm: true, payStep: 0 })
          }
          disabled={Object.keys(selectedPlan).length === 0}
          sx={{ flex: { lg: "45%" } }}
        />
        <Box sx={{ flex: { lg: "45%" } }}></Box>
        <Button
          variant="text"
          text="مشاهده ویژگی پلن ها"
          endIcon={
            <ArrowLeftIcon
              color={forestShadowColor}
              width={16}
              style={{ marginBottom: 2 }}
            />
          }
          sx={{
            fontSize: 13,
            border: { xs: `1px solid ${silverSageColor}`, lg: "none" },
            flex: { lg: "45%" },
          }}
          onClick={() => navigate(PRICING)}
        />
      </Box>
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <Title
          text="تاریخچه خرید ها"
          containerProps={{ sx: { mb: 2 } }}
          size={4}
        />
      </Box>
      <Box sx={{ display: { xs: "none", lg: "block" }, mb: 5 }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    backgroundColor: arcticMistColor,
                    borderBottom: 0,
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                  }}
                >
                  سطح حساب
                </TableCell>
                {/* <TableCell
                    sx={{
                      fontWeight: 600,
                      backgroundColor: arcticMistColor,
                      borderBottom: 0,
                    }}
                    align="left"
                  >
                    نوع تراکنش
                  </TableCell> */}
                <TableCell
                  sx={{
                    fontWeight: 600,
                    backgroundColor: arcticMistColor,
                    borderBottom: 0,
                  }}
                  align="left"
                >
                  مبلغ پرداختی
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    backgroundColor: arcticMistColor,
                    borderBottom: 0,
                  }}
                  align="left"
                >
                  تاریخ و ساعت شروع
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    backgroundColor: arcticMistColor,
                    borderBottom: 0,
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                  }}
                  align="left"
                >
                  تاریخ و ساعت پایان
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plansHistory.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderColor: silverSageColor }}
                  >
                    {row.plan_name}
                  </TableCell>
                  {/* <TableCell
                      align="left"
                      sx={{ borderColor: silverSageColor }}
                    >
                      {row.type}
                    </TableCell> */}
                  <TableCell
                    align="left"
                    sx={{
                      borderColor: silverSageColor,
                    }}
                  >
                    {row.amount} €
                  </TableCell>
                  <TableCell align="left" sx={{ borderColor: silverSageColor }}>
                    {moment(row.created_at).format("HH:mm - jYYYY/jMM/jDD")}
                  </TableCell>
                  <TableCell align="left" sx={{ borderColor: silverSageColor }}>
                    {moment(row.end_date).format("HH:mm - jYYYY/jMM/jDD")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {renderDialog}
      {renderDrawer}
    </Box>
  );
};

export default Plan;

export const PlanButton = ({
  isSelected,
  title,
  title2,
  title3,
  desc,
  onClick,
  disabled,
  sx,
}) => {
  return (
    <Box
      onClick={onClick}
      component={MuiButton}
      sx={{
        backgroundColor: arcticMistColor,
        p: 2,
        borderRadius: 2.5,
        display: "block",
        textAlign: "left",
        border: "1px solid transparent",
        borderColor: isSelected ? primaryColor : "transparent",
        ...sx,
      }}
      fullWidth
      disabled={disabled}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Radio checked={isSelected} sx={{ p: 0 }} disabled={disabled} />
        <Typography
          sx={{
            fontSize: { xs: 14, lg: 16 },
            fontWeight: 600,

            ml: 1,
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            ml: 1,
            mr: "auto",
            visibility: disabled ? "visible" : "hidden",
            border: `1px solid ${primaryColor}`,
            // px: 1,
            borderRadius: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 72,
            height: 22,
          }}
        >
          <Box
            sx={{
              width: 5,
              height: 5,
              backgroundColor: primaryColor,
              mr: 0.5,
              borderRadius: 100,
            }}
          ></Box>
          <Typography sx={{ fontSize: 12, color: blackColor }}>
            پلن فعال
          </Typography>
        </Box>
        <Typography
          sx={{
            color: isSelected && primaryColor,
            fontSize: 10,
            fontWeight: 300,
          }}
        >
          {title3}
        </Typography>
        <Typography sx={{ color: isSelected && primaryColor, fontWeight: 800 }}>
          {title2}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: { xs: 10, lg: 12 },
          pl: { lg: 4 },
          pt: 2,
          lineHeight: 2,
        }}
      >
        {desc}
      </Typography>
    </Box>
  );
};

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    height: 32,
    bottom: 18,
    borderRadius: 100,
    width: 60,
    height: 24,
  },
  "& .MuiTabs-indicatorSpan": {
    width: 60,
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
    backgroundColor: "#F7FAFA",
    borderRadius: 100,
    width: 190,
    height: 34,
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    flex: 0.5,
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    // fontSize: theme.typography.pxToRem(15),
    // marginRight: theme.spacing(1),
    // color: "rgba(255, 255, 255, 0.7)",

    "&.MuiTab-root": {
      minWidth: 60,
      width: 60,
      maxWidth: 60,
      padding: 0,
      fontSize: 12,
      minHeight: 30,
    },
    "&.Mui-selected": {
      color: "#fff",
      zIndex: 1,
    },
  })
);
