import React, { useState } from "react";
import {
  Box,
  ButtonBase,
  CircularProgress,
  Typography,
  alpha,
} from "@mui/material";

import {
  arcticMistColor,
  primaryColor,
  sageGreenColor,
  softCyanColor,
  whiteColor,
} from "../../assets/colors";
import {
  ArrowLeftIcon,
  GlobeIcon,
  LinkIcon,
  Logo,
  LogoFull,
  PowerIcon,
} from "../../assets/icons";

import YekcardBg from "./yekcard-bg.png";
import { useDispatch, useSelector } from "react-redux";
import { updateUserStepRequestAction } from "../../redux/actions/dashboardActions";
import Button from "../../components/Button";
import { logoutRequestAction } from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import { UPDATE_USER_STEP_STARTED } from "../../redux/actionTypes";

const Onboarding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    auth: { user },
    dashboard,
  } = useSelector((state) => ({
    auth: state.auth,
    dashboard: state.dashboard,
  }));
  const [loading, setLoading] = useState(null);

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: { lg: "center" },
          order: { xs: 1, lg: 0 },
          backgroundColor: whiteColor,
          position: "relative",
        }}
      >
        <Button
          text="خروج از حساب"
          fullWidth={false}
          sx={{
            position: "absolute",
            top: 70,
            right: 48,
            display: { xs: "none", lg: "flex" },
          }}
          startIcon={<PowerIcon />}
          color="coralRed"
          onClick={() => dispatch(logoutRequestAction())}
        />
        <Box sx={{ mx: { xs: "15px", lg: 3 }, mb: { xs: 3, lg: 5 } }}>
          <Typography
            sx={{
              fontSize: { xs: 20, lg: 24 },
              fontWeight: { xs: 700, lg: 600 },
              mb: 2.5,
              mt: { xs: "32px", lg: 0 },
            }}
          >
            کدام محصول یک پی برای شما مناسب است؟
          </Typography>
          <Typography
            sx={{
              mb: 4,
              fontSize: { xs: 14, lg: 16 },
              fontWeight: { xs: 300, lg: 400 },
              lineHeight: 2,
            }}
          >
            توضیحات را بخوانید و بر اساس نیاز کسب و کارتان ، محصول مورد نظر خود
            را انتخاب کنید.
          </Typography>
          <CustomButton
            icon={
              <GlobeIcon
                width={35}
                height={35}
                color={primaryColor}
                style={{ marginLeft: 16 }}
              />
            }
            primary="وب گیت"
            secondary="برای وب سایتم نیاز به درگاه اینترنتی دارم"
            loading={
              loading === "Webgate" &&
              dashboard.rType === UPDATE_USER_STEP_STARTED
            }
            disabled={dashboard.rType === UPDATE_USER_STEP_STARTED}
            onClick={() => {
              dispatch(
                updateUserStepRequestAction({
                  webgate: true,
                  identityStatus: user.identity_status,
                  navigate,
                })
              );
              setLoading("Webgate");
            }}
          />
          <CustomButton
            icon={
              <LinkIcon
                width={35}
                height={35}
                color={primaryColor}
                style={{ marginLeft: 16 }}
              />
            }
            primary="پی لینک"
            secondary=" وب سایت ندارم اما برای هر یک از محصولات / خدمات خود نیاز به یک صفحه پرداخت اختصاصی دارم"
            loading={
              loading === "Paylink" &&
              dashboard.rType === UPDATE_USER_STEP_STARTED
            }
            disabled={dashboard.rType === UPDATE_USER_STEP_STARTED}
            onClick={() => {
              setLoading("Paylink");
              dispatch(
                updateUserStepRequestAction({
                  paylink: true,
                  identityStatus: user.identity_status,
                  navigate,
                })
              );
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: { lg: 455 },
          height: { xs: 256, lg: "auto" },
          backgroundImage: `url(${YekcardBg})`,
          backgroundSize: "cover",
          // mixBlendMode: "hard-light",
          backgroundColor: alpha(primaryColor, 0.35),
          backgroundBlendMode: "lighten",
          pl: { xs: "27px", lg: 0 },
          pr: { xs: "21px", lg: 0 },
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          component={LogoFull}
          sx={{
            marginLeft: "30px",
            marginTop: "56px",
            display: { xs: "none", lg: "block" },
          }}
        />
        <Box
          sx={{
            display: { xs: "flex", lg: "none" },
            alignItems: "center",
            justifyContent: "space-between",
            // pr: "21px",
            // pl: "27px",
            mb: "18px",
            mt: "27px",
          }}
        >
          <Box component={Logo} />
          <Button
            text="خروج از حساب"
            fullWidth={false}
            sx={{
              display: { lg: "none" },
              borderRadius: 100,
              zIndex: 1,
            }}
            startIcon={<PowerIcon />}
            color="arcticMistWhite"
            onClick={() => dispatch(logoutRequestAction())}
          />
        </Box>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: { xs: 700, lg: 800 },
            color: whiteColor,
            mt: { lg: "108px" },
            ml: { lg: "30px" },
            mb: { xs: 2, lg: 1.75 },
            lineHeight: 1.8,
          }}
        >
          با چند کلیک ساده ، دشبورد
          <br /> اختصاصی خود را در یک پی بسازید!
        </Typography>
        <Typography
          sx={{
            color: alpha(whiteColor, 0.8),
            ml: { lg: "30px" },
            fontWeight: 300,
            fontSize: { xs: 14, lg: 16 },
          }}
        >
          به سوالات پاسخ دهید و مراحل را طی کنید.
        </Typography>
        <Box
          sx={{
            width: 747,
            height: 747,
            backgroundColor: "#FFFFFF1A",
            position: "absolute",
            borderRadius: 100,
            bottom: -500,
            left: -100,
          }}
        ></Box>
        <Box
          sx={{
            width: 593,
            height: 593,
            backgroundColor: "#FFFFFF1A",
            position: "absolute",
            borderRadius: 100,
            bottom: -480,
            left: 80,
          }}
        ></Box>
      </Box>
    </>
  );
};

export default Onboarding;

const CustomButton = ({
  icon,
  primary,
  secondary,
  disabled,
  loading,
  onClick,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: arcticMistColor,
        minHeight: "91px",
        display: "flex",
        alignItems: "center",
        px: 2,
        borderRadius: 3,
        minWidth: { lg: "688px" },
        maxWidth: "auto",
        cursor: "pointer",
        transition: "all 0.5s",
        mb: 2.5,
        "&:hover": {
          backgroundColor: softCyanColor,
        },
      }}
      onClick={onClick}
      component={ButtonBase}
      disabled={disabled}
    >
      {icon}
      <Box sx={{ flex: 1, py: 1, textAlign: "left" }}>
        <Typography sx={{ fontWeight: 600 }}>{primary}</Typography>
        <Typography sx={{ fontSize: 13, lineHeight: 2, color: sageGreenColor }}>
          {secondary}
        </Typography>
      </Box>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <ArrowLeftIcon width={24} height={24} />
      )}
    </Box>
  );
};
