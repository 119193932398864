import React, { useState } from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
// import Dialog from "@mui/material/Dialog";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { arcticMistColor } from "../../assets/colors";
// import { IconButton } from "@mui/material";
// import { CloseIcon } from "../../assets/icons";
// import { appBarHeight, drawerWidth } from "../../utils/constants";

const ImagePreview = ({ imageSrc, altText }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Typography sx={{ fontSize: 12, mb: 1 }}>{altText}</Typography>
      <Box
        sx={{
          height: 46,
          width: 75,
          backgroundColor: arcticMistColor,
          borderRadius: "7px",
          px: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Avatar
          src={imageSrc}
          sx={{ width: 35, height: 23, borderRadius: "3px" }}
        />
      </Box>

      {/* <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        BackdropProps={{
          sx: {
            background: "rgba(218, 218, 218, 0.21)",
            backdropFilter: " blur(9px)",
            left: drawerWidth + 252,
            top: appBarHeight,
            borderLeft: "1px solid #E7EFEC",
          },
        }}
        sx={{ left: drawerWidth + 252, top: appBarHeight }}
        scroll="body"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{altText}</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon width={18} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img
            src={imageSrc}
            alt={altText}
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
      </Dialog> */}
    </Box>
  );
};

export default ImagePreview;
