import {
  createProductAPI,
  deleteProductAPI,
  getProductsListAPI,
} from "../../services/api/product";
import {
  CREATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_STARTED,
  CREATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_STARTED,
  DELETE_PRODUCT_SUCCESS,
  GET_PRODUCTS_LIST_FAILURE,
  GET_PRODUCTS_LIST_STARTED,
  GET_PRODUCTS_LIST_SUCCESS,
} from "../actionTypes";
// --------------------------------------------------------------------------------
export const getProductsListRequestAction = ({
  limit,
  offset,
  setCount,
  onSuccess,
}) => {
  return async (dispatch) => {
    dispatch(getProductsListStartedAction());
    try {
      let response = await getProductsListAPI({ limit, offset });
      if (response.status === "success") {
        const products = await Promise.all(
          response.data.products.map(async (product) => {
            const imgBlob = await fetch(product.image)
              .then((res) => res.blob())
              .catch(() => null);
            return {
              ...product,
              image: URL.createObjectURL(imgBlob),
            };
          })
        );

        dispatch(
          getProductsListSuccessAction({
            productList: products,
          })
        );
        setCount && setCount(response.data.count);
        onSuccess(products);
      } else {
        dispatch(getProductsListFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getProductsListFailureAction(error.error_description));
    }
  };
};

export const getProductsListStartedAction = () => ({
  type: GET_PRODUCTS_LIST_STARTED,
});

export const getProductsListSuccessAction = (payload) => ({
  type: GET_PRODUCTS_LIST_SUCCESS,
  payload,
});

export const getProductsListFailureAction = (message) => ({
  type: GET_PRODUCTS_LIST_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const createProductRequestAction = ({
  formData,
  setOpen,
  setState,
  setCount,
  onSuccess,
}) => {
  return async (dispatch) => {
    dispatch(createProductStartedAction());
    try {
      let response = await createProductAPI({ formData });
      if (response.status === "success") {
        dispatch(createProductSuccessAction());
        dispatch(
          getProductsListRequestAction({ limit: 20, offset: 1, setCount })
        );
        setOpen(false);
        setState &&
          setState({
            image: "",
            title: "",
            detail: "",
            description: "",
            amount: "",
            errors: {},
          });
        onSuccess(response.data);
      } else {
        dispatch(createProductFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(createProductFailureAction(error.error_description));
    }
  };
};

export const createProductStartedAction = () => ({
  type: CREATE_PRODUCT_STARTED,
});

export const createProductSuccessAction = () => ({
  type: CREATE_PRODUCT_SUCCESS,
});

export const createProductFailureAction = (message) => ({
  type: CREATE_PRODUCT_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const deleteProductRequestAction = ({ productId, setCount }) => {
  return async (dispatch) => {
    dispatch(deleteProductStartedAction());
    try {
      let response = await deleteProductAPI({ productId });
      if (response.status === "success") {
        dispatch(deleteProductSuccessAction());
        dispatch(
          getProductsListRequestAction({ limit: 20, offset: 1, setCount })
        );
      } else {
        dispatch(deleteProductFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(deleteProductFailureAction(error.error_description));
    }
  };
};

export const deleteProductStartedAction = () => ({
  type: DELETE_PRODUCT_STARTED,
});

export const deleteProductSuccessAction = () => ({
  type: DELETE_PRODUCT_SUCCESS,
});

export const deleteProductFailureAction = (message) => ({
  type: DELETE_PRODUCT_FAILURE,
  payload: message,
});
