// App.js
import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  Avatar,
  Box,
  Chip,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { drawerWidth } from "../../utils/constants";
import {
  arcticMistColor,
  forestShadowColor,
  goldenAmberColor,
  primaryColor,
} from "../../assets/colors";
import { ArrowDownIcon2, EuroIcon3, GlobeIcon } from "../../assets/icons";
import {
  getLastTransactionsRequestAction,
  getTransactionsCoordinatesRequestAction,
} from "../../redux/actions/transactionActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-jalaali";
import Title from "../../components/Title";
import CustomAccordion from "../../components/Accordion";
import { currencyIcon, typeStyles } from "../TransactionsList/constants";
import { numberWithCommas } from "../../utils/helpers";
import { statusObj } from "../Account/constants";
import { GET_LAST_TRANSACTIONS_STARTED } from "../../redux/actionTypes";

const CustomIcon = ({ text, color }) => {
  const icon = L.divIcon({
    html: `
      <svg width="68" height="74" viewBox="0 0 68 74" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f_129_3772)">
<rect x="18.468" y="42" width="31" height="14" rx="7" fill="${color}"/>
</g>
<path d="M36.5385 0H31.782C22.1894 0 17.3931 0 13.9584 2.32095C12.5246 3.28982 11.2898 4.52459 10.321 5.9584C8 9.3931 8 14.1894 8 23.7821C8 33.3747 8 38.171 10.321 41.6057C11.2898 43.0395 12.5246 44.2743 13.9584 45.2432C17.3931 47.5641 22.1894 47.5641 31.782 47.5641H36.5385C46.1311 47.5641 50.9274 47.5641 54.3621 45.2432C55.7959 44.2743 57.0307 43.0395 57.9996 41.6057C60.3205 38.171 60.3205 33.3747 60.3205 23.7821C60.3205 14.1894 60.3205 9.3931 57.9996 5.9584C57.0307 4.52459 55.7959 3.28982 54.3621 2.32095C50.9274 0 46.1311 0 36.5385 0Z" fill="${color}"/>
<path d="M32.0799 51.2596L28.5098 47.6894C26.9591 46.1387 28.0574 43.4873 30.2504 43.4873H37.3906C39.5836 43.4873 40.6819 46.1387 39.1312 47.6894L35.5611 51.2596C34.5998 52.2208 33.0412 52.2208 32.0799 51.2596Z" fill="${color}"/>
<text x="33" y="20" text-anchor="middle" fill="white" font-family="IRANSansXFaNum">${text}</text>
 <text x="33" y="35" text-anchor="middle" fill="white" font-family="IRANSansXFaNum">تراکنش</text>
<defs>
<filter id="filter0_f_129_3772" x="0.567957" y="24.1" width="66.8" height="49.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="8.95" result="effect1_foregroundBlur_129_3772"/>
</filter>
</defs>
</svg>
    `,
    iconSize: [25, 41],
    iconAnchor: [-10, 60],
    popupAnchor: [1, -34],
    className: "custom-icon", // you can use this class to style your icon
  });

  return icon;
};

const CustomMarker = ({ position, color, text, onClick }) => {
  const icon = CustomIcon({ text, color });

  return (
    <Marker
      position={position}
      icon={icon}
      eventHandlers={{ click: onClick }}
    />
  );
};

const TransactionsMap = () => {
  const dispatch = useDispatch();

  const { transactionsCoordinates, rType } = useSelector(
    (state) => state.transaction
  );
  const [open, setOpen] = useState(false);
  const [lastTransactions, setLastTransactions] = useState({
    transactions: [],
  });
  const loading = rType === GET_LAST_TRANSACTIONS_STARTED;

  const bounds = [
    [-85, -180], // Southwest corner (latitude, arbitrary longitude)
    [85, 180], // Northeast corner (latitude, arbitrary longitude)
  ];

  useEffect(() => {
    dispatch(getTransactionsCoordinatesRequestAction());
  }, []);

  const handleMarkerClick = (position) => {
    // setClickedMarkers({});
    // setClickedMarkers((prevState) => ({
    //   ...prevState,
    //   [index]: !prevState[index],
    // }));
    dispatch(
      getLastTransactionsRequestAction({
        countryCode: position.ip_location,
        setOpen,
        setLastTransactions,
      })
    );
  };

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const renderStatus = (status) => {
    switch (status) {
      case "Pending":
        return (
          <Chip
            sx={{ borderRadius: "7px" }}
            label="در انتظار تایید"
            color="goldenCream"
          />
        );
      case "Approved":
        return (
          <Chip
            sx={{ borderRadius: "7px" }}
            label="تایید شده"
            color="emeraldOasis"
          />
        );
      case "Declined":
        return (
          <Chip sx={{ borderRadius: "7px" }} label="رد شده" color="rosyGlow" />
        );
    }
  };

  const handleMapClick = () => {
    setLastTransactions({ transactions: [] });
    setOpen(false);
  };

  const columns = [
    {
      field: "event", //access nested data with dot notation
      label: "نوع سرویس",
    },
    {
      field: "amount",
      label: "مبلغ تراکنش",
      renderCell: (row) => numberWithCommas(Math.trunc(row.amount)),
    },
    {
      field: "currency",
      label: "واحد پولی",
    },

    {
      field: "status",
      label: "وضعیت",
      renderCell: (row) => renderStatus(row.status),
    },

    {
      field: "created_at",
      label: "تاریخ",
      renderCell: (row) => moment(row.date).format("HH:mm - jYYYY/jM/jD"),
    },
  ];

  const renderDrawer = () => {
    return (
      <SwipeableDrawer
        variant="persistent"
        anchor="bottom"
        open={open}
        onClose={() => {
          setLastTransactions({ transactions: [] });
          setOpen(false);
        }}
        onOpen={() => setOpen(true)}
        componentsProps={{
          backdrop: {
            sx: { left: { lg: drawerWidth }, backgroundColor: "transparent" },
          },
        }}
        PaperProps={{
          sx: {
            left: { lg: drawerWidth + 30 },
            right: { lg: 30 },
            borderTopRightRadius: { xs: 20, lg: 25 },
            borderTopLeftRadius: { xs: 20, lg: 25 },
            // p: 2,
            boxShadow: "0px -17px 44.3px 0px #7F91A133",
            pl: "21px",
            pr: "22px",
            pt: "37px",
            pb: "24px",
          },
        }}
      >
        <Box sx={{ display: { lg: "none" } }}>
          <Title
            text="جزئیات"
            size={4}
            containerProps={{ sx: { ml: "5px", mb: "23px" } }}
          />
          <Box
            sx={{
              height: 41,
              p: 1,
              display: "flex",
              alignItems: "center",
              backgroundColor: arcticMistColor,
              borderRadius: 1.5,
              mb: "9px",
            }}
          >
            <Avatar
              sx={{ width: 24, height: 24 }}
              src={`https://flagcdn.com/${lastTransactions.country_code?.toLowerCase()}.svg`}
            />
            <Typography sx={{ fontSize: 12, mx: 1 }}>نام کشور:</Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
              {lastTransactions.country_name}
            </Typography>
          </Box>
          <Box
            sx={{
              height: 41,
              p: 1,
              display: "flex",
              backgroundColor: arcticMistColor,
              alignItems: "center",
              borderRadius: 1.5,
              // justifyContent: "space-between",
              mb: "9px",
            }}
          >
            <Box
              sx={{
                width: 9,
                height: 9,
                backgroundColor: primaryColor,
                borderRadius: "3px",
              }}
            ></Box>
            <Typography sx={{ fontSize: 12, ml: 1, flex: 1 }}>
              حجم تراکنش ها:
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontWeight: 500, color: primaryColor }}>
                {lastTransactions.total_amount?.toFixed(2)}{" "}
              </Typography>
              <EuroIcon3
                width={12}
                height={12}
                style={{ marginRight: "3px" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              height: 41,
              p: 1,
              display: "flex",
              backgroundColor: arcticMistColor,
              alignItems: "center",
              borderRadius: 1.5,
              // justifyContent: "space-between",
              mb: "29px",
            }}
          >
            <Box
              sx={{
                width: 9,
                height: 9,
                backgroundColor: goldenAmberColor,
                borderRadius: "3px",
              }}
            ></Box>
            <Typography sx={{ fontSize: 12, ml: 1, flex: 1 }}>
              تعداد تراکنش:
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: goldenAmberColor,
                }}
              >
                {lastTransactions.total_count}{" "}
              </Typography>
              <Typography
                sx={{
                  fontSize: 8,
                  fontWeight: 500,
                  ml: 0.25,
                }}
              >
                تراکنش
              </Typography>
            </Box>
          </Box>
          <Title
            text="تراکنش های اخیر"
            size={4}
            containerProps={{ sx: { ml: "5px", mb: "18px" } }}
          />
          <CustomAccordion
            data={lastTransactions.transactions}
            columns={columns}
            renderSummary={(item) => {
              const amount = Math.trunc(item.amount);
              return (
                <>
                  <Box
                    sx={{
                      backgroundColor: arcticMistColor,
                      width: 36,
                      height: 36,
                      borderRadius: 100,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mr: 1,
                    }}
                  >
                    <GlobeIcon height={20} width={20} />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitBoxOrient: "vertical",
                      flex: 1,
                    }}
                  >
                    {item.event}
                  </Typography>
                  <ArrowDownIcon2
                    color={statusObj[item.status]?.color}
                    style={{ marginLeft: "3px" }}
                  />
                  <Typography
                    sx={{
                      // mx: 0.5,
                      color: statusObj[item.status]?.color,
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                  >
                    {numberWithCommas(amount)}
                  </Typography>
                  {currencyIcon[item.currency]}
                </>
              );
            }}
          />
        </Box>
        <TableContainer sx={{ display: { xs: "none", lg: "block" } }}>
          <Table aria-label="simple table">
            {/* <TableHead>
              <TableRow>
                <TableCell>Dessert (100g serving)</TableCell>
                <TableCell align="right">Calories</TableCell>
                <TableCell align="right">Fat&nbsp;(g)</TableCell>
                <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                <TableCell align="right">Protein&nbsp;(g)</TableCell>
              </TableRow>
            </TableHead> */}
            <TableBody>
              {[
                ...lastTransactions.transactions,
                ...Array(3 - lastTransactions.transactions.length).fill(null),
              ].map((row, index) => {
                let firstCell = null;
                switch (index) {
                  case 0:
                    firstCell = (
                      <Box
                        sx={{
                          height: 41,
                          p: 1,
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: arcticMistColor,
                          borderRadius: 1.5,
                        }}
                      >
                        <Avatar
                          sx={{ width: 24, height: 24 }}
                          src={`https://flagcdn.com/${lastTransactions.country_code?.toLowerCase()}.svg`}
                        />
                        <Typography sx={{ fontSize: 12, mx: 1 }}>
                          نام کشور:
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                          {lastTransactions.country_name}
                        </Typography>
                      </Box>
                    );
                    break;
                  case 1:
                    firstCell = (
                      <Box
                        sx={{
                          height: 41,
                          p: 1,
                          display: "flex",
                          backgroundColor: arcticMistColor,
                          alignItems: "center",
                          borderRadius: 1.5,
                        }}
                      >
                        <Box
                          sx={{
                            width: 9,
                            height: 9,
                            backgroundColor: primaryColor,
                          }}
                        ></Box>
                        <Typography sx={{ fontSize: 12, ml: 1, flex: 1 }}>
                          حجم تراکنش ها:
                        </Typography>
                        <Typography
                          sx={{ fontWeight: 500, color: primaryColor }}
                        >
                          {lastTransactions.total_amount?.toFixed(2)}{" "}
                        </Typography>
                        <EuroIcon3
                          width={12}
                          height={12}
                          style={{ marginRight: "3px" }}
                        />
                      </Box>
                    );
                    break;
                  case 2:
                    firstCell = (
                      <Box
                        sx={{
                          height: 41,
                          p: 1,
                          display: "flex",
                          backgroundColor: arcticMistColor,
                          alignItems: "center",
                          borderRadius: 1.5,
                          // justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            width: 9,
                            height: 9,
                            backgroundColor: goldenAmberColor,
                            borderRadius: "3px",
                          }}
                        ></Box>
                        <Typography sx={{ fontSize: 12, ml: 1, flex: 1 }}>
                          تعداد تراکنش:
                        </Typography>

                        <Typography
                          sx={{
                            fontWeight: 500,
                            color: goldenAmberColor,
                          }}
                        >
                          {lastTransactions.total_count}{" "}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 8,
                            fontWeight: 500,
                            ml: 0.25,
                          }}
                        >
                          تراکنش
                        </Typography>
                      </Box>
                    );
                    break;
                }

                if (row === null) {
                  return (
                    <TableRow
                      key={`empty-${index}`}
                      // sx={{ "& > *": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" sx={{ border: 0 }}>
                        {firstCell}
                      </TableCell>
                      <TableCell align="right" sx={{ border: 0 }}></TableCell>
                      <TableCell align="right" sx={{ border: 0 }}></TableCell>
                      <TableCell align="right" sx={{ border: 0 }}></TableCell>
                      <TableCell align="right" sx={{ border: 0 }}></TableCell>
                    </TableRow>
                  );
                }

                return (
                  <TableRow key={row.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ width: 100, border: 0 }}
                    >
                      {firstCell}
                    </TableCell>
                    <TableCell align="left" sx={{ width: 80, border: 0 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          sx={{
                            backgroundColor: arcticMistColor,
                            width: 36,
                            height: 36,
                            borderRadius: 100,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mr: 1,
                          }}
                        >
                          <GlobeIcon height={20} width={20} />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 600,
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitBoxOrient: "vertical",
                            flex: 1,
                          }}
                        >
                          {row.event}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left" sx={{ width: 80, border: 0 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ArrowDownIcon2
                          color={statusObj[row.status]?.color}
                          style={{ marginLeft: "3px" }}
                        />
                        <Typography
                          sx={{
                            // mx: 0.5,
                            color: statusObj[row.status]?.color,
                            fontWeight: 700,
                            fontSize: 14,
                          }}
                        >
                          {numberWithCommas(Math.trunc(row.amount))}
                        </Typography>
                        {currencyIcon[row.currency]}
                      </Box>

                      {/* {numberWithCommas(Math.trunc(row.amount))} */}
                    </TableCell>
                    <TableCell align="left" sx={{ width: 60, border: 0 }}>
                      {moment(row.date).format("HH:mm - jYYYY/jM/jD")}
                    </TableCell>
                    <TableCell align="left" sx={{ width: 180, border: 0 }}>
                      {renderStatus(row.status)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </SwipeableDrawer>
    );
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 67px)",
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: loading ? 999 : 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: loading ? "blur(10px)" : "blur(0px)",
          transition: "all 1s",
        }}
      ></Box>
      <MapContainer
        center={[45, 0]}
        minZoom={3}
        maxZoom={4}
        zoom={3}
        style={{ height: "calc(100vh - 67px)", width: "100%" }}
        zoomControl={false}
        maxBounds={bounds}
        maxBoundsViscosity={1.0}
        worldCopyJump={true} // Enable wrapping
        attributionControl={false}
      >
        <ChangeView onClick={handleMapClick} />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {transactionsCoordinates.map((position, idx) => (
          <CustomMarker
            position={[position.lat, position.lon]}
            text={position.count}
            color={
              lastTransactions.country_code === position.ip_location
                ? primaryColor
                : forestShadowColor
            }
            onClick={() => handleMarkerClick(position)}
          />
        ))}
      </MapContainer>
      {renderDrawer()}
    </Box>
  );
};

export default TransactionsMap;

function ChangeView({ onClick }) {
  const map = useMapEvents({
    click: onClick,
  });

  return null;
}
