import { Box, Chip, Dialog, Tab, Tabs, Typography } from "@mui/material";
import { CheckCircleIcon, ListIcon } from "../../assets/icons";
import {
  aquaMist,
  arcticMistColor,
  primaryColor,
  slateGrayColor,
  whisperingSnowColor,
  whiteColor,
} from "../../assets/colors";
import { useState } from "react";
import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import PaylinksList from "./PaylinksList";
import ProductPaylinks from "./ProductPaylinks";
import { appBarHeight, drawerWidth } from "../../utils/constants";
import Button from "../../components/Button";
import { PAYLINK_CREATE } from "../routes";

const PL = () => {
  const location = useLocation();
  const paths = location.pathname.split("/");

  const [selectedTab, setSelectedTab] = useState("PersonalPaylinks");
  const [open, setOpen] = useState(location.state?.showSuccessfullyMessage);

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderSuccessfullyMessage = (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          borderRadius: 5,
          boxShadow: "0px 0px 77px 20px #5F5F5F08",
        },
      }}
      maxWidth="sm"
      BackdropProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(9px)",
          left: { lg: drawerWidth },
          top: appBarHeight,
        },
      }}
      sx={{
        left: { lg: drawerWidth },
        top: appBarHeight,
        // display: { xs: "none", lg: "block" },
      }}
      onClose={() => setOpen(false)}
      scroll="body"
    >
      <Box
        sx={{
          backgroundColor: { lg: arcticMistColor },
          p: "30px",
          borderRadius: "20px",
          width: { lg: 488 },
        }}
      >
        <Box
          sx={{
            display: { lg: "flex" },
            // textAlign: "center",
            alignItems: "center",
            mb: { lg: "32px" },
          }}
        >
          <Box
            sx={{
              backgroundColor: aquaMist,
              width: 85,
              height: 85,
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "15px",
              mb: { xs: "32px", lg: 0 },
            }}
          >
            <CheckCircleIcon width={43} height={43} color={primaryColor} />
          </Box>
          <Box sx={{ flex: 1, ml: 2 }}>
            <Typography sx={{ fontWeight: 700, mb: 1 }}>
              درخواست پی‌لینک ثبت شد
            </Typography>
            <Typography
              sx={{ lineHeight: 1.8, fontSize: 14, color: slateGrayColor }}
            >
              درخواست پی‌لینک شما با موفقیت ثبت و در دست بررسی قرار گرفت از صبر
              و شکیبایی شما سپاس گذاریم
            </Typography>
          </Box>
        </Box>
        <Button
          text="بستن"
          onClick={() => setOpen(false)}
          sx={{ display: { xs: "none", lg: "flex" } }}
        />
      </Box>
    </Dialog>
  );

  return (
    <Box sx={{ flex: 1, p: { xs: "15px", lg: 3 } }}>
      <Box
        sx={{
          backgroundColor: whiteColor,
          p: 3,
          border: `1px solid ${whisperingSnowColor}`,
          borderRadius: 2.5,
          display: { xs: "flex", lg: "none" },
          alignItems: "center",
          mb: 2,
          py: 4,
        }}
      >
        <ListIcon color={primaryColor} />
        <Typography sx={{ ml: 1, fontWeight: 600 }}>لیست پی لینک ها</Typography>
        <Chip
          label="+ افزودن پی لینک جدید"
          color="brightTurquoise"
          sx={{ ml: "auto" }}
          onClick={() => navigate(PAYLINK_CREATE)}
        />
      </Box>
      <Box>
        <Box
          sx={{
            backgroundColor: whiteColor,
            p: { lg: 3, xs: 2 },
            border: `1px solid ${whisperingSnowColor}`,
            borderRadius: 2.5,
          }}
        >
          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
              alignItems: "center",
              mb: 3,
            }}
          >
            <ListIcon color={primaryColor} />{" "}
            <Typography sx={{ ml: 1, fontWeight: 600, fontSize: 18 }}>
              لیست پی لینک ها
            </Typography>{" "}
            <Chip
              label="+ افزودن پی لینک جدید"
              color="brightTurquoise"
              sx={{ ml: "auto" }}
              onClick={() => navigate(PAYLINK_CREATE)}
            />
          </Box>
          <AntTabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="ant example"
            sx={{ display: { xs: "none", lg: "flex" } }}
          >
            <AntTab label="پی لینک های شخصی" value="PersonalPaylinks" />
            <AntTab label="پی لینک های محصول" value="ProductPaylinks" />
          </AntTabs>
          {selectedTab === "PersonalPaylinks" ? (
            <PaylinksList />
          ) : (
            <ProductPaylinks />
          )}
        </Box>
      </Box>
      {renderSuccessfullyMessage}
    </Box>
  );
};

export default PL;

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  //   "& .MuiTabs-indicator": {
  //     backgroundColor: "#1890ff",
  //   },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    padding: "10px 8px",
    fontSize: 12,
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    // fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    // "&:hover": {
    //   color: "#40a9ff",
    //   opacity: 1,
    // },
    "&.Mui-selected": {
      //   color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
