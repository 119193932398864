import { Avatar, Typography } from "@mui/material";
import Button from "../Button";
import { useRef, useState } from "react";
import { EditIcon } from "../../assets/icons";

const UploadFile = ({ label, onChange, value }) => {
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    onChange(file);
  };

  const renderButton = (text, src) => (
    <Button
      text={text}
      color="arcticMist3"
      onClick={() => fileInputRef.current.click()}
      startIcon={<Avatar sx={{ width: 24, height: 24 }} src={src} />}
    />
  );

  return (
    <>
      <input
        type="file"
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      <Typography sx={{ fontSize: 12, mb: 1 }}>{label}</Typography>
      {image ? (
        renderButton(<EditIcon width={20} />, URL.createObjectURL(image))
      ) : value ? (
        renderButton(<EditIcon width={20} />, value)
      ) : (
        <Button
          text="+ افزودن"
          color="arcticMist3"
          onClick={() => fileInputRef.current.click()}
        />
      )}
    </>
  );
};

export default UploadFile;
