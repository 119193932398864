import { useEffect, useState, createElement } from "react";
import { Box, Chip, Divider, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  arcticMistColor,
  forestShadowColor,
  graphiteGrayColor,
  primaryColor,
  silverCloudColor,
  silverMistColor,
  slateGrayColor,
  whiteColor,
} from "../../assets/colors";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { renderCurrencyCircleIcon } from "../../utils/helpers";
import { ChevronDownIcon as ArrowDownIcon } from "../../assets/icons";
import Pagination from "@mui/material/Pagination";
import MuiPaginationItem from "@mui/material/PaginationItem";

const CustomAccordion = ({
  columns,
  data,
  count,
  onChangePage,
  page,
  renderSummary,
}) => {
  const { t } = useTranslation();

  const [rowPerPage, setRowPerPage] = useState(`20 ${t("items")}`);

  return (
    <>
      <Box>
        {data.map((item, index) => (
          <Accordion
            key={index}
            // expanded={expanded === "panel1"}
            // onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              {renderSummary(item)}
            </AccordionSummary>
            <AccordionDetails sx={{ pr: 1, pl: 1.25 }}>
              {columns.map((column, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    py: 2,
                    flexWrap: "wrap",
                  }}
                >
                  {column.hideLabel ? (
                    column.renderCell(item)
                  ) : (
                    <>
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: 500,
                          color: slateGrayColor,
                        }}
                      >
                        {column.label}:
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        {"renderCell" in column
                          ? column.renderCell(item)
                          : item[column.field]}
                      </Typography>
                    </>
                  )}
                </Box>
              ))}
            </AccordionDetails>
            {/* <Divider sx={{ borderColor: silverCloudColor }} />
            <AccordionDetails sx={{ p: 1.5 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                  {t("receipt")}:
                </Typography>
                <Chip
                  label={t("detail")}
                  onClick={() => {}}
                  color="primary"
                  sx={{
                    height: "auto",
                    borderRadius: "19px",
                    px: 0.63,
                    py: 0.25,
                    fontSize: 14,
                  }}
                />
              </Box>
            </AccordionDetails> */}
          </Accordion>
        ))}
      </Box>
      {count ? (
        <Box
          sx={{
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: slateGrayColor, fontSize: 12 }}>
            نمایش{" "}
            {Math.min(
              count,
              rowPerPage.replace("items", "") * page -
                (rowPerPage.replace("items", "") - data.length)
            )}{" "}
            آیتم از {count} آیتم جدول
          </Typography>
          <Pagination
            variant="outlined"
            count={Math.ceil(count / rowPerPage.replace("items", ""))}
            page={page}
            // siblingCount={0}
            onChange={(event, value) => onChangePage(value)}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                // slots={{
                //   previous: () => (
                //     <ArrowDownIcon style={{ transform: "rotate(90deg)" }} />
                //   ),
                //   next: () => (
                //     <ArrowDownIcon style={{ transform: "rotate(-90deg)" }} />
                //   ),
                // }}
                rowPerPage={rowPerPage.replace("items", "")}
                count={count}
              />
            )}
            sx={{
              "& .MuiPagination-ul": {
                flexDirection: "row-reverse",
              },
            }}
          />
        </Box>
      ) : null}
    </>
  );
};

export default CustomAccordion;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  borderRight: `1px solid ${silverCloudColor}`,
  borderLeft: `1px solid ${silverCloudColor}`,
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  "&:first-child": {
    borderTop: `1px solid ${silverCloudColor}`,
  },
  "&:last-child": {
    borderBottom: `1px solid ${silverCloudColor}`,
  },
  "&:before": {
    display: "none",
  },
  "&:first-of-type": {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  "&:last-of-type": {
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
  "&:nth-child(even)": {
    backgroundColor: arcticMistColor,
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDownIcon width={17} color={forestShadowColor} />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: 60,
  paddingLeft: theme.spacing(1.25),
  paddingRight: theme.spacing(1),
  // flexDirection: "row-reverse",
  // "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
  //   transform: "rotate(90deg)",
  // },
  "& .MuiAccordionSummary-content": {
    // marginLeft: theme.spacing(1),
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const PaginationItem = styled(MuiPaginationItem)((props) => {
  return {
    "&.MuiPaginationItem-root": {
      // flexDirection: "row-reverse",
      height: 27,
      minWidth: 27,
      borderColor: silverMistColor,
      fontWeight: 600,
      // backgroundColor: silverCloudColor,
      // margin: 0,
      // borderRadius: 0,
      // borderTopLeftRadius: props.page === 1 ? 38 : 0,
      // borderBottomLeftRadius: props.page === 1 ? 38 : 0,
      // borderTopRightRadius:
      //   props.page === Math.ceil(props.count / props.rowPerPage) ? 38 : 0,
      // borderBottomRightRadius:
      //   props.page === Math.ceil(props.count / props.rowPerPage) ? 38 : 0,
      fontSize: 11,
      "&.Mui-selected": {
        backgroundColor: primaryColor,
        color: whiteColor,
        // boxShadow: "0px 0px 4.6px 0px rgba(65, 173, 206, 0.76);",
        // borderRadius: 5,
        // height: 27,
        // width: 27,
      },
    },
    "&.MuiPaginationItem-ellipsis": {
      // height: 25,
    },
    "&.MuiPaginationItem-previousNext": {
      borderRadius: "100%",
      marginLeft: 4,
      marginRight: 4,
      minWidth: "auto",
      width: 25,
    },
  };
});
