import { PAYLINK_CREATE, WEBGATE_CREATE } from "../../pages/routes";
import {
  changePollStatusAPI,
  getAnnouncementsAPI,
  getBusinessCategoriesAPI,
  getDashboardDataAPI,
  getPollLinkAPI,
  seenAnnouncementAPI,
  setBusinessCategoryAPI,
  updateUserStepAPI,
} from "../../services/api/dashboard";
import {
  CHANGE_POLL_STATUS_FAILURE,
  CHANGE_POLL_STATUS_STARTED,
  CHANGE_POLL_STATUS_SUCCESS,
  GET_ANNOUNCEMENTS_FAILURE,
  GET_ANNOUNCEMENTS_STARTED,
  GET_ANNOUNCEMENTS_SUCCESS,
  GET_BUSINESS_CATEGORIES_FAILURE,
  GET_BUSINESS_CATEGORIES_STARTED,
  GET_BUSINESS_CATEGORIES_SUCCESS,
  GET_DASHBOARD_DATA_FAILURE,
  GET_DASHBOARD_DATA_STARTED,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_POLL_LINK_FAILURE,
  GET_POLL_LINK_STARTED,
  GET_POLL_LINK_SUCCESS,
  SEEN_ANNOUNCEMENT_FAILURE,
  SEEN_ANNOUNCEMENT_STARTED,
  SEEN_ANNOUNCEMENT_SUCCESS,
  SET_BUSINESS_CATEGORY_FAILURE,
  SET_BUSINESS_CATEGORY_STARTED,
  SET_BUSINESS_CATEGORY_SUCCESS,
  UPDATE_USER_STEP_FAILURE,
  UPDATE_USER_STEP_STARTED,
  UPDATE_USER_STEP_SUCCESS,
} from "../actionTypes";
import { getUserProfileRequestAction } from "./authActions";
// --------------------------------------------------------------------------------
export const getDashboardDataRequestAction = () => {
  return async (dispatch) => {
    dispatch(getDashboardDataStartedAction());
    try {
      let response = await getDashboardDataAPI();
      if (response.status === "success") {
        dispatch(
          getDashboardDataSuccessAction({
            data: response.data,
          })
        );
      } else {
        dispatch(
          getDashboardDataFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(getDashboardDataFailureAction(error.error_description));
    }
  };
};

export const getDashboardDataStartedAction = () => ({
  type: GET_DASHBOARD_DATA_STARTED,
});

export const getDashboardDataSuccessAction = (payload) => ({
  type: GET_DASHBOARD_DATA_SUCCESS,
  payload,
});

export const getDashboardDataFailureAction = (message) => ({
  type: GET_DASHBOARD_DATA_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const updateUserStepRequestAction = ({
  webgate = false,
  paylink,
  navigate,
  identityStatus,
}) => {
  return async (dispatch) => {
    dispatch(updateUserStepStartedAction());
    try {
      let response = await updateUserStepAPI({ webgate, paylink });
      if (response.status === "success") {
        dispatch(updateUserStepSuccessAction());
        dispatch(getUserProfileRequestAction());
        if (identityStatus === "Approved" && webgate) {
          navigate(WEBGATE_CREATE);
        } else if (identityStatus === "Approved" && paylink) {
          navigate(PAYLINK_CREATE);
        }
      } else {
        dispatch(updateUserStepFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(updateUserStepFailureAction(error.error_description));
    }
  };
};

export const updateUserStepStartedAction = () => ({
  type: UPDATE_USER_STEP_STARTED,
});

export const updateUserStepSuccessAction = () => ({
  type: UPDATE_USER_STEP_SUCCESS,
});

export const updateUserStepFailureAction = (message) => ({
  type: UPDATE_USER_STEP_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getAnnouncementsRequestAction = () => {
  return async (dispatch) => {
    dispatch(getAnnouncementsStartedAction());
    try {
      let response = await getAnnouncementsAPI();
      if (response.status === "success") {
        dispatch(
          getAnnouncementsSuccessAction({ announcements: response.data })
        );
      } else {
        dispatch(
          getAnnouncementsFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(getAnnouncementsFailureAction(error.error_description));
    }
  };
};

export const getAnnouncementsStartedAction = () => ({
  type: GET_ANNOUNCEMENTS_STARTED,
});

export const getAnnouncementsSuccessAction = (payload) => ({
  type: GET_ANNOUNCEMENTS_SUCCESS,
  payload,
});

export const getAnnouncementsFailureAction = (message) => ({
  type: GET_ANNOUNCEMENTS_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const seenAnnouncementRequestAction = ({ announcementsId }) => {
  return async (dispatch) => {
    dispatch(seenAnnouncementStartedAction());
    try {
      let response = await seenAnnouncementAPI({ announcementsId });
      if (response.status === "success") {
        dispatch(seenAnnouncementSuccessAction());
        dispatch(getAnnouncementsRequestAction());
      } else {
        dispatch(
          seenAnnouncementFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(seenAnnouncementFailureAction(error.error_description));
    }
  };
};

export const seenAnnouncementStartedAction = () => ({
  type: SEEN_ANNOUNCEMENT_STARTED,
});

export const seenAnnouncementSuccessAction = (payload) => ({
  type: SEEN_ANNOUNCEMENT_SUCCESS,
  payload,
});

export const seenAnnouncementFailureAction = (message) => ({
  type: SEEN_ANNOUNCEMENT_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getBusinessCategoriesRequestAction = () => {
  return async (dispatch) => {
    dispatch(getBusinessCategoriesStartedAction());
    try {
      let response = await getBusinessCategoriesAPI();
      if (response.status === "success") {
        dispatch(
          getBusinessCategoriesSuccessAction({
            businessCategories: response.data,
          })
        );
      } else {
        dispatch(
          getBusinessCategoriesFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(getBusinessCategoriesFailureAction(error.error_description));
    }
  };
};

export const getBusinessCategoriesStartedAction = () => ({
  type: GET_BUSINESS_CATEGORIES_STARTED,
});

export const getBusinessCategoriesSuccessAction = (payload) => ({
  type: GET_BUSINESS_CATEGORIES_SUCCESS,
  payload,
});

export const getBusinessCategoriesFailureAction = (message) => ({
  type: GET_BUSINESS_CATEGORIES_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const setBusinessCategoryRequestAction = ({
  businessCategoryId,
  businessCategoryName,
  setOpen,
}) => {
  return async (dispatch) => {
    dispatch(setBusinessCategoryStartedAction());
    try {
      let response = await setBusinessCategoryAPI({
        businessCategoryId,
        businessCategoryName,
      });
      if (response.status === "success") {
        dispatch(setBusinessCategorySuccessAction());
        dispatch(getUserProfileRequestAction());
        setOpen(false);
      } else {
        dispatch(
          setBusinessCategoryFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(setBusinessCategoryFailureAction(error.error_description));
    }
  };
};

export const setBusinessCategoryStartedAction = () => ({
  type: SET_BUSINESS_CATEGORY_STARTED,
});

export const setBusinessCategorySuccessAction = (payload) => ({
  type: SET_BUSINESS_CATEGORY_SUCCESS,
  payload,
});

export const setBusinessCategoryFailureAction = (message) => ({
  type: SET_BUSINESS_CATEGORY_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getPollLinkRequestAction = ({ setOpenPoll }) => {
  return async (dispatch) => {
    dispatch(getPollLinkStartedAction());
    try {
      let response = await getPollLinkAPI();
      if (response.status === "success") {
        dispatch(getPollLinkSuccessAction());
        setOpenPoll(response.data);
      } else {
        dispatch(getPollLinkFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getPollLinkFailureAction(error.error_description));
    }
  };
};

export const getPollLinkStartedAction = () => ({
  type: GET_POLL_LINK_STARTED,
});

export const getPollLinkSuccessAction = (payload) => ({
  type: GET_POLL_LINK_SUCCESS,
  payload,
});

export const getPollLinkFailureAction = (message) => ({
  type: GET_POLL_LINK_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const changePollStatusRequestAction = ({ status, onSuccess }) => {
  return async (dispatch) => {
    dispatch(changePollStatusStartedAction());
    try {
      let response = await changePollStatusAPI({ status });
      if (response.status === "success") {
        dispatch(changePollStatusSuccessAction());
        onSuccess();
      } else {
        dispatch(
          changePollStatusFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(changePollStatusFailureAction(error.error_description));
    }
  };
};

export const changePollStatusStartedAction = () => ({
  type: CHANGE_POLL_STATUS_STARTED,
});

export const changePollStatusSuccessAction = (payload) => ({
  type: CHANGE_POLL_STATUS_SUCCESS,
  payload,
});

export const changePollStatusFailureAction = (message) => ({
  type: CHANGE_POLL_STATUS_FAILURE,
  payload: message,
});
