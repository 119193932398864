import {
  Avatar,
  Box,
  Chip,
  Dialog,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  brightRedColor,
  mistyRoseColor,
  primaryColor,
  slateGrayColor,
} from "../../assets/colors";
import { TrashIcon, TrashIcon2, WarningIcon } from "../../assets/icons";
import CustomTextInput from "../../components/CustomTextInput";
import Button from "../../components/Button";
import Title from "../../components/Title";
import { useEffect, useRef, useState } from "react";
import { formatTime } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAccountRequestAction,
  sendMobileCodeRequestAction,
  verifyMobileCodeRequestAction,
} from "../../redux/actions/authActions";
import CustomOtpInput from "../../components/CustomOtpInput";
import {
  DELETE_ACCOUNT_STARTED,
  LOGOUT_STARTED,
  SEND_MOBILE_CODE_STARTED,
  VERIFY_MOBILE_CODE_STARTED,
} from "../../redux/actionTypes";
import { appBarHeight, drawerWidth } from "../../utils/constants";

const DeleteAccount = () => {
  const dispatch = useDispatch();

  const { rType } = useSelector((state) => state.auth);

  const [mobile, setMobile] = useState("");
  const [counter, setCounter] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [errors, setErrors] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    let timer = null;
    timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    return () => clearInterval(timer);
  }, [counter]);

  // useEffect(() => {
  //   showOtpInput && inputRef.current.focus();
  // }, [showOtpInput]);

  const handleSubmit = () => {
    dispatch(
      verifyMobileCodeRequestAction({
        otp,
        setErrors,
        onSuccess: () => {
          setShowConfirm(true);
        },
      })
    );
  };

  const renderConfirm = (
    <Dialog
      open={showConfirm}
      PaperProps={{
        sx: {
          borderRadius: 5,
          boxShadow: "0px 0px 77px 20px #5F5F5F08",
        },
      }}
      maxWidth="sm"
      BackdropProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(9px)",
          left: { lg: drawerWidth },
          top: appBarHeight,
        },
      }}
      sx={{
        left: { lg: drawerWidth },
        top: appBarHeight,
        // display: { xs: "none", lg: "block" },
      }}
      onClose={() => setShowConfirm(false)}
      scroll="body"
    >
      <Box
        sx={{
          p: "30px",
          borderRadius: "20px",
          width: { lg: 488 },
        }}
      >
        <Box
          sx={{
            display: { lg: "flex" },
            // textAlign: "center",
            alignItems: "center",
            mb: "32px",
          }}
        >
          <Box
            sx={{
              backgroundColor: mistyRoseColor,
              width: 85,
              height: 85,
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "15px",
              mb: { xs: "32px", lg: 0 },
            }}
          >
            <TrashIcon2 width={43} height={43} color={primaryColor} />
          </Box>
          <Box sx={{ flex: 1, ml: 2 }}>
            <Typography sx={{ fontWeight: 700, mb: 1 }}>
              شما در حال حذف حساب کاربری خود هستید!
            </Typography>
            <Typography
              sx={{ lineHeight: 1.8, fontSize: 14, color: slateGrayColor }}
            >
              با زدن دکمه تایید ، حساب کاربری شما برای همیشه حذف میشود ، آیا
              مطمئن هستید؟
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            text="تایید و حذف حساب"
            onClick={() => dispatch(deleteAccountRequestAction())}
            color="fireEngineRed"
            loading={
              rType === VERIFY_MOBILE_CODE_STARTED ||
              rType === DELETE_ACCOUNT_STARTED ||
              rType === LOGOUT_STARTED
            }
          />
          <Button
            text="انصراف و بازگشت"
            onClick={() => setShowConfirm(false)}
            color="arcticMist"
          />
        </Box>
      </Box>
    </Dialog>
  );

  return (
    <Box
      sx={{
        flex: 1,
        py: { xs: "15px", lg: 2.25 },
        px: { xs: "15px", lg: 3.75 },
      }}
    >
      <Title
        text="حذف حساب کاربری"
        size={4}
        color={brightRedColor}
        containerProps={{ sx: { display: { xs: "none", lg: "flex" }, mb: 6 } }}
      />
      <Box sx={{ width: { lg: 490 }, m: "auto" }}>
        <Box
          sx={{
            backgroundColor: mistyRoseColor,
            borderRadius: 3,
            p: 3,
            textAlign: "center",
            mb: 3,
          }}
        >
          <WarningIcon style={{ marginBottom: 5 }} />
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
              lineHeight: 2,
              textAlign: "left",
            }}
          >
            در صورتی که اکانت کاربری خود را حذف کنید، بر اساس قوانین و مقررات یک
            پی، اطلاعات مربوط به آن تا 5 سال نزد یک پی باقی خواهد ماند. همچنین
            پس از حذف اکانت خود، دیگر قادر به بازگرداندن آن نخواهید بود.
          </Typography>
        </Box>
        <Typography sx={{ fontSize: 14, mb: 2 }}>
          جهت حذف حساب کاربری، موبایل خود را وارد کنید.
        </Typography>
        <Box sx={{ display: { lg: "flex" }, alignItems: "center" }}>
          <CustomTextInput
            label="موبایل"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            placeholder="... وارد کنید"
            error={errors.mobile}
            helperText={errors.mobile}
            number
            endAdornment={
              <InputAdornment>
                <Typography sx={{ fontSize: 14, mr: 0.5 }}>98+</Typography>
              </InputAdornment>
            }
          />
          <Chip
            label={
              counter > 0
                ? `ارسال مجدد ${formatTime(counter)}`
                : "ارسال کد تایید"
            }
            onClick={(e) => {
              e.preventDefault();
              const errorObj = {};

              if (mobile === "")
                errorObj.mobile = "لطفا شماره موبایل خود را وارد کنید.";
              // if (!EMAIL_REGEX.test(email)) errorObj.email = "آدرس ایمیل صحیح نمی باشد.";

              setErrors(errorObj);

              if (Object.keys(errorObj).length > 0) return;

              dispatch(
                sendMobileCodeRequestAction({
                  mobile,
                  event: "deleteAccount",
                  onSuccess: () => {
                    setCounter(120);
                    setShowOtpInput(true);
                  },
                })
              );
            }}
            sx={{ mt: 1, ml: 1 }}
            color="emeraldOasis"
            disabled={counter > 0 || rType === SEND_MOBILE_CODE_STARTED}
          />
        </Box>
        <Box sx={{ width: { lg: 318 }, mx: "auto", mb: 2, mt: 4 }}>
          <CustomOtpInput
            label="ورود کد تایید"
            length={5}
            value={otp}
            onComplete={(value) => setOtp(value)}
            containerProps={{
              sx: {
                label: { fontSize: 14 },
                visibility: showOtpInput ? "visible" : "hidden",
                opacity: showOtpInput ? 1 : 0,
                transition: "1s all",
                "& .MuiInputBase-root": { mr: 1 },
              },
            }}
            helperText={errors.otp}
            error={errors.otp}
          />
        </Box>
        <Button
          text="حذف حساب"
          startIcon={<TrashIcon />}
          color="fireEngineRed"
          onClick={handleSubmit}
          disabled={otp.length < 5}
          sx={{ display: { xs: "none", lg: "flex" } }}
          loading={rType === VERIFY_MOBILE_CODE_STARTED}
        />
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          p: 3,
          justifyContent: "center",
          display: { xs: "block", lg: "none" },
        }}
      >
        <Button
          text="حذف حساب"
          startIcon={<TrashIcon />}
          color="fireEngineRed"
          disabled={otp.length < 5}
          onClick={() => setShowConfirm(true)}
        />
      </Box>
      {renderConfirm}
    </Box>
  );
};

export default DeleteAccount;
