import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import Button from "../../../components/Button";
import { appBarHeight, drawerWidth } from "../../../utils/constants";
import { useContext, useEffect } from "react";
import {
  BriefcaseIcon,
  CloseIcon,
  EditIcon,
  Simplification,
  UserIcon,
} from "../../../assets/icons";
import {
  arcticMistColor,
  blackColor,
  primaryColor,
  silverGrayColor,
  silverSageColor,
  slateGrayColor,
  whiteColor,
} from "../../../assets/colors";
import MuiStepper from "../../../components/Stepper";
import { useDispatch, useSelector } from "react-redux";
import { getUserCompanyRequestAction } from "../../../redux/actions/userActions";
import { statusObj } from "../../Webgate/constants";
import StepFisrt from "./StepFirst";
import StepSecond from "./StepSecond";
import { AccountContext } from "../../../context/AccountContext";
import { persianOrdinal } from "../constants";
import ImagePreview from "../../../components/ImagePreview";

const AddCompany = () => {
  const dispatch = useDispatch();
  const { userCompany } = useSelector((state) => state.user);
  const { state, setState } = useContext(AccountContext);

  const steps = [
    { label: "ورود اطلاعات اولیه شرکت", value: 0, icon: Simplification },
    {
      label: "بارگذاری مدارک شرکت",
      value: 1,
      icon: UserIcon,
    },
  ];

  useEffect(() => {
    dispatch(getUserCompanyRequestAction());
  }, []);

  useEffect(() => {
    let timer = null;
    timer =
      state.counter > 0 &&
      setInterval(() => setState({ counter: state.counter - 1 }), 1000);
    return () => clearInterval(timer);
  }, [state.counter]);

  const renderSteps = () => {
    switch (state.activeStep) {
      case 0:
        return <StepFisrt />;
      case 1:
        return <StepSecond />;
    }
  };

  return (
    <>
      <Box
        sx={{
          border: `1px solid ${silverSageColor}`,
          borderRadius: "15px",
          p: 1,
          minHeight: 68,
          display: { xs: "none", lg: "block" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            rowGap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: "100%",
            }}
          >
            <BriefcaseIcon color={primaryColor} />
            <Typography sx={{ fontWeight: 700, ml: 1 }}>
              اطلاعات شرکت
            </Typography>
            {userCompany.status && (
              <Chip
                label={statusObj[userCompany.status].label}
                color={statusObj[userCompany.status].color}
                sx={{
                  ml: "8px",
                  height: 20,
                  fontSize: 12,
                  fontWeight: 500,
                }}
                icon={
                  <Box
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette[statusObj[userCompany.status].color]
                          .contrastText,
                      height: 4,
                      width: 4,
                      borderRadius: 100,
                    }}
                  ></Box>
                }
              />
            )}
            {userCompany.name ? (
              <Button
                variant="outlined"
                text="ویرایش"
                fullWidth={false}
                sx={{
                  ml: "auto",
                  color: slateGrayColor,
                  borderRadius: "47px",
                }}
                startIcon={<EditIcon width={18} height={18} />}
                color="silverSage"
                onClick={() => {
                  setState({
                    open: true,
                    name: userCompany.name,
                    registrationNumber: userCompany.national_id,
                    email: userCompany.email,
                    lastChanges: userCompany.last_changes,
                    logo: userCompany.logo,
                    officialNewspaper: userCompany.official_newspaper,
                    companyOwners: userCompany.company_owners.map((owenr) => ({
                      signatureImage: owenr.attachment,
                      firstname: owenr.first_name,
                      lastname: owenr.last_name,
                      mobile: owenr.mobile,
                      nationalId: owenr.national_id,
                    })),
                  });
                }}
              />
            ) : (
              <Button
                text="+ افزودن شرکت"
                fullWidth={false}
                sx={{ ml: "auto", borderRadius: "47px" }}
                color="emeraldOasis"
                onClick={() => setState({ open: true })}
              />
            )}
          </Box>
          {userCompany.name ? (
            <>
              <Box sx={{ flex: "1 1 15%" }}>
                <Typography sx={{ fontSize: 12 }}>نام شرکت</Typography>
                <Typography>{userCompany.name}</Typography>
              </Box>
              <Box sx={{ flex: "1 1 15%" }}>
                <Typography sx={{ fontSize: 12 }}>شناسه ملی شرکت</Typography>
                <Typography>{userCompany.national_id}</Typography>
              </Box>
              <Box sx={{ flex: "1 1 65%" }}>
                <Typography sx={{ fontSize: 12 }}>ایمیل شرکت</Typography>
                <Typography>{userCompany.email}</Typography>
              </Box>
              <ImagePreview altText="لوگو شرکت" imageSrc={userCompany.logo} />
              <ImagePreview
                altText="آگهی آخرین تغییرات شرکت"
                imageSrc={userCompany.last_changes}
              />
              <ImagePreview
                altText="تصویر روزنامه رسمی"
                imageSrc={userCompany.official_newspaper}
              />
              {userCompany.company_owners?.map((owner, index) => (
                <>
                  <Divider
                    sx={{
                      border: `1px dashed ${silverGrayColor}`,
                      flex: "100%",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 3,
                      flex: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: arcticMistColor,
                        height: 30,
                        width: 30,
                        borderRadius: 100,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <UserIcon width={18} height={18} color={primaryColor} />{" "}
                    </Box>
                    <Typography sx={{ fontWeight: 700, fontSize: 14, ml: 1 }}>
                      اطلاعات صاحب امضا {persianOrdinal[index]}
                    </Typography>
                  </Box>
                  <Box sx={{ flex: "15%" }}>
                    <Typography sx={{ fontSize: 12 }}>نام</Typography>
                    <Typography>{owner.first_name}</Typography>
                  </Box>
                  <Box sx={{ flex: "15%" }}>
                    <Typography sx={{ fontSize: 12 }}>نام خانوادگی</Typography>
                    <Typography>{owner.last_name}</Typography>
                  </Box>
                  <Box sx={{ flex: "15%" }}>
                    <Typography sx={{ fontSize: 12 }}>کد ملی</Typography>
                    <Typography>{owner.national_id}</Typography>
                  </Box>
                  <Box sx={{ flex: "50%" }}>
                    <Typography sx={{ fontSize: 12 }}>موبایل</Typography>
                    <Typography>{owner.mobile}</Typography>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <ImagePreview
                      altText="تصویر گواهی امضا"
                      imageSrc={owner.attachment}
                    />
                  </Box>
                </>
              ))}
            </>
          ) : null}
        </Box>
      </Box>
      <Dialog
        open={state.open}
        PaperProps={{
          sx: {
            borderRadius: 5,
            boxShadow: "0px 0px 77px 20px #5F5F5F08;",
            width: 686,
          },
        }}
        BackdropProps={{
          sx: {
            background: "rgba(218, 218, 218, 0.21)",
            backdropFilter: " blur(9px)",
            left: drawerWidth + 252,
            top: appBarHeight,
            borderLeft: `1px solid ${silverSageColor}`,
          },
        }}
        sx={{ left: drawerWidth + 252, top: appBarHeight }}
        onClose={() => setState({ open: false })}
        scroll="body"
      >
        <IconButton
          aria-label="close"
          onClick={() => setState({ open: false })}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon width={24} height={24} color={whiteColor} />
        </IconButton>
        <DialogTitle
          sx={{
            backgroundColor: blackColor,
            fontSize: 16,
            color: whiteColor,
          }}
        >
          افزودن شرکت
        </DialogTitle>
        <DialogContent dividers sx={{ borderBottom: "none", p: "13px" }}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ ml: 1, flex: 1, mr: 4 }}>{renderSteps()}</Box>
            <Box
              sx={{
                backgroundColor: arcticMistColor,
                border: `1px solid ${silverSageColor}`,
                borderRadius: "15px",
                minHeight: 647 - 56,
                width: 254,
                px: "13px",
                py: "15px",
              }}
            >
              <MuiStepper
                activeStep={state.activeStep}
                steps={steps}
                //   orientation="horizontal"
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddCompany;
