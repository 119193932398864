import platform from "platform";

export const drawerWidth = 300;
export const appBarHeight = 65;

export const isProduction = process.env.REACT_APP_ENV === "production";

export const defaultMaxLength = 25;
export const emailMaxLength = 35;

export const api2BaseUrl = process.env.REACT_APP_API_URL;
export const adminapiBaseUrl = "https://adminapi.yekpay.com";
export const oldDashboardUrl = `${process.env.REACT_APP_OLD_DASHBOARD_URL}/fa/auth/login`;

export const fullHeight = platform.os.family === "Android" ? "100vh" : "100dvh";

export const dmSans = "DM Sans";
export const notoSans = "Noto Sans";
export const iranSans = "IRANSansXVF";
export const iranSansFaNum = "IRANSansXFaNum";
export const roboto = "Roboto";
