import {
  amberBlazeColor,
  crimsonBlazeColor,
  periwinkleBlue,
  primaryColor,
  slateGrayColor,
} from "../../assets/colors";

export const statusObj = {
  Pending: {
    label: "در انتظار تایید",
    color: amberBlazeColor,
  },
  Approved: {
    label: "فعال",
    color: primaryColor,
  },
  Declined: {
    label: "رد شده",
    color: crimsonBlazeColor,
  },
  Canceled: {
    label: "غیر فعال",
    color: slateGrayColor,
  },
  Suspended: {
    label: "تعلیق شده",
    color: periwinkleBlue,
  },
};

export const persianOrdinal = [
  "اول",
  "دوم",
  "سوم",
  "چهارم",
  "پنجم",
  "ششم",
  "هفتم",
  "هشتم",
  "نهم",
  "دهم",
  "یازدهم",
  "دوازدهم",
  "سیزدهم",
  "چهاردهم",
  "پانزدهم",
  "شانزدهم",
  "هفدهم",
  "هجدهم",
  "نوزدهم",
  "بیستم",
];

export const subscriptionAmount = {
  Personal: {
    1: "رایگان",
    6: "رایگان",
    12: "رایگان",
  },
  Business: {
    1: "9.99",
    6: "53.99",
    12: "99.99",
  },
  BusinessPlus: {
    1: "49.99",
    6: "259.99",
    12: "499.99",
  },
};

export const periods = {
  1: "ماهیانه",
  6: "6 ماهه",
  12: "سالیانه",
};
